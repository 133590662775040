import { Mail, Visibility } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { IIntervallDTO, IIntervallPosition, TextTemplates, defaultIntervall, useIntervalls } from "../../Contexts/IntervallContext/IntervallProvider";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import Android12Switch from "../Buttons/Switch/Android12Switch";
import EditCreateIntervallPositionDialog from "../Dialogs/EditCreateIntervalDialog/EditCreateIntervallPositionDialog";
import MultiLineInputField from "../InputField/MultiLineInputField";
import SubscriptionPositionRow from "../IntervallCards/SubscriptionPositionRow";
import FormRow from "./FormUtils/FormRow";
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored";
import PdfPreviewDialog from "../Dialogs/PdfPreviewDialog/PdfPreviewDialog";
import { IDocument } from "../../Contexts/DocumentContext/DocumentContext";
import { validateIntervallForm } from "../IntervallCards/IntervallDataCard";
import { useCompanyData } from "../../Contexts/CompanyDataContext/CompanyDataProvider";
import ResetFormButton from "../Buttons/ResetFormButton/ResetFormButton";
import { ITextModule, placeholderTextModule, TextModuleDocumentType, useGetDefaultTextModulesQuery } from "../../redux/apiSlices/TextModule";


export type IntervallFormModes = "createIntervall" | "editIntervall";

const EditCreateIntervallForm = ({ intervall, setIntervall, mode, error, setError, setFormMode }: { setError: any, error: any, intervall: IIntervallDTO, setIntervall: any, mode?: any, setFormMode?: Dispatch<SetStateAction<IntervallFormModes>> }) => {
    const { companies } = useContacts();
    const { generatePdfPreview, sendPdfPreview } = useIntervalls();
    const { persons } = useContacts();

    const { data: defaultTextModules } = useGetDefaultTextModulesQuery();
    const [intervallTextModule, setIntervallTextModule] = useState<ITextModule>(defaultTextModules?.find(t => t.type === TextModuleDocumentType.Intervall) ?? placeholderTextModule);
    const [intervallSEPATextModule, setIntervallSEPATextModule] = useState<ITextModule>(defaultTextModules?.find(t => t.type === TextModuleDocumentType.IntervallSEPA) ?? placeholderTextModule);

    useEffect(() => {
        if (defaultTextModules) {
            setIntervallTextModule(defaultTextModules.find(t => t.type === TextModuleDocumentType.Intervall)!);
            setIntervallSEPATextModule(defaultTextModules.find(t => t.type === TextModuleDocumentType.IntervallSEPA)!);
        }
    }, [defaultTextModules])

    const [periode, setPeriode] = useState([{ id: 1, title: "Tage" }, { id: 2, title: "Monate" }, { id: 3, title: "Jahre" }]);
    const [chosenPeriod, setChosenPeriod] = useState<1 | 2 | 3>((intervall?.periodIsDays ? 1 : intervall?.periodIsMonths ? 2 : intervall?.periodIsYears ? 3 : 1));

    const [open, setOpen] = useState<boolean>(false)

    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewDocument, setPreviewDocument] = useState<IDocument>();

    const [customerType, setCustomerType] = useState(false);

    const { bills, billPrefixes } = useBills();

    // Create a Date object for today's date
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const handleGeneratePreview = () => {
        if (validateIntervallForm(intervall, setError)) {
            setPreviewOpen(true);
            generatePdfPreview(intervall)
                .then((res: any) => {
                    setPreviewDocument(res);
                })
        }
    }

    const handleSendPreview = () => {
        if (validateIntervallForm(intervall, setError)) {
            setPreviewOpen(true);
            sendPdfPreview(intervall)
                .then((res: any) => {
                    setPreviewDocument(res);
                })
        }
    }

    useEffect(() => { setChosenPeriod(intervall ? (intervall.periodIsDays ? 1 : intervall.periodIsMonths ? 2 : 3) : 1) }, [intervall])

    const { companyData } = useCompanyData();

    const handleReset = () => {
        if (setFormMode) {
            setFormMode("createIntervall");
        }
        setIntervall(defaultIntervall);
    }

    console.log("Intervall", intervall, "Mode", mode)



    const handleSwitchChange = () => {
        setCustomerType((prevType) => !prevType);
    };

    const handleCheckboxChange = (type: string) => {
        setCustomerType(type === 'private');
    }




    return <>
        <form className='form'>
            <div className='form-row noselect'>
                {/*<span style={{ fontSize: "14px" }}>Firmenkunde</span>
            <Switch color="secondary" checked={customerType} onChange={handleSwitchChange} />
<span style={{ fontSize: "14px" }}>Privatkunde</span>*/}
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!customerType}
                                onChange={() => handleCheckboxChange('company')}
                                color="secondary"
                                size="small"

                            />
                        }
                        label="Firmenkunde"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={customerType}
                                onChange={() => handleCheckboxChange('private')}
                                color="secondary"
                                size="small"
                            />
                        }
                        label="Privatkunde"
                    />
                </div>
                {/*<span style={{ fontSize: "14px" }}>Firmenkunde</span>
                <Switch color="secondary" checked={customerType} onChange={(e, val) => setCustomerType((oldVal: boolean) => !oldVal)} />
<span style={{ fontSize: "14px" }}>Privatkunde</span>*/}
            </div>
            <FormRow
                type='select'
                label="Kunde"
                placeholder='Kunde auswählen'
                value={customerType === false ? intervall?.customerOrganizationId : intervall.customerPersonId}
                onChange={(val: number) => {
                    if (customerType === false) {
                        setIntervall((prev: IIntervallDTO) => ({ ...prev, customerOrganizationId: val, customerPersonId: undefined }))
                    } else {
                        setIntervall((prev: IIntervallDTO) => ({ ...prev, customerOrganizationId: undefined, customerPersonId: val }))
                    }
                }}
                selectOptions={{
                    //Legt den Titel-Key des aktiven Objekts fest -> Wert wird in das Feld eingefügt.
                    titleKey: customerType === false ? "name" : "completeName",
                    //Legt den Wert-Key des aktiven Objekts fest -> Wert wird demnach an den onChange-Handler übergeben.
                    valueKey: "id",
                    options: customerType === false ? companies : persons
                }}
                error={error?.customerError}
                errorMessage="Bitte wählen Sie einen Kunden aus"
            />
            <FormRow
                type='select'
                label="Rechnungskreis"
                placeholder='Rechnungskreis auswählen'
                value={intervall?.prefix}
                onChange={(val) => { setIntervall((prev: any) => ({ ...prev, prefix: val })); }}
                selectOptions={{
                    titleKey: 'prefix',
                    valueKey: 'id',
                    options: billPrefixes ?? []
                }}
                error={error?.prefixError}
                errorMessage="Bitte wählen Sie einen Rechnungskreis aus"
            />
            <FormRow
                label="Titel"
                placeholder="Titel eingeben"
                type="text"
                value={intervall?.title ?? ""}
                onChange={(val: string) => setIntervall((prev: any) => ({ ...prev, title: val }))}
                error={error?.titleError}
                errorMessage={InputFieldErrorMessages.TitleError}
            />
            <FormRow
                label="Beschreibung"
                placeholder="Beschreibung eingeben"
                type="multiline"
                value={intervall?.description ?? ""}
                onChange={(val: string) => setIntervall((prev: any) => ({ ...prev, description: val }))}
            //error={error?.titleError}
            //errorMessage={InputFieldErrorMessages.TitleError}
            />
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>SEPA-Einzug</span>
                <span>
                    <Android12Switch color="secondary" checked={intervall?.automatedPaymentProcessing} onChange={(e, val) => {
                        if (val) {
                            setIntervall((old: IIntervallDTO) => ({ ...old, automatedPaymentProcessing: val, textBefore: intervallSEPATextModule.textStart, textAfter: intervallSEPATextModule.textEnd }))
                        } else {
                            setIntervall((old: IIntervallDTO) => ({ ...old, automatedPaymentProcessing: val, textBefore: intervallTextModule.textStart, textAfter: intervallTextModule.textEnd }))
                        }
                    }
                    } />
                </span>
            </div>
            <FormRow
                label="Rechnungstext vor Gesamtübersicht"
                placeholder="Rechnungstext vor Gesamtübersicht eingeben"
                type="multiline"
                value={intervall?.textBefore ?? intervallTextModule.textStart}
                onChange={(val: string) => setIntervall((prev: IIntervallDTO) => ({ ...prev, textBefore: val }))}
            //error={error?.titleError}
            //errorMessage={InputFieldErrorMessages.TitleError}
            />
            <FormRow
                label="Rechnungstext nach Gesamtübersicht"
                placeholder="Rechnungstext nach Gesamtübersicht eingeben"
                type="multiline"
                value={intervall?.textAfter ?? intervallTextModule.textEnd}
                onChange={(val: string) => setIntervall((prev: IIntervallDTO) => ({ ...prev, textAfter: val }))}
                error={error?.titleError}
                errorMessage={InputFieldErrorMessages.TitleError}
            />
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <FormRow
                    label="Abrechnungsperiode"
                    placeholder="Abrechnungsperiode eingeben"
                    type="number"
                    step='1'
                    value={intervall?.period ?? ""}
                    onChange={(val: string) => setIntervall((old: any) => ({ ...old, period: parseFloat(val) }))}
                    error={error?.periodError}
                    errorMessage="Bitte geben Sie eine Abrechnungsberiode ein"
                />
                <FormRow
                    label="Einheit der Abrechnungsperiode"
                    type="select"
                    value={chosenPeriod}
                    onChange={(val: any) => {
                        setChosenPeriod(val);
                        setIntervall((old: IIntervallDTO) => ({ ...old, periodIsDays: val === 1 ? true : false, periodIsMonths: val === 2 ? true : false, periodIsYears: val === 3 ? true : false }))
                    }}
                    selectOptions={{
                        options: periode,
                        titleKey: "title",
                        valueKey: "id"
                    }}
                    placeholder='Periode auswählen'
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <FormRow
                    value={intervall?.begin}
                    /* @ts-ignore */
                    onChange={(val) => setIntervall((old: IIntervallDTO) => ({ ...old, begin: val }))}
                    label="Startdatum der Intervallabrechnung"
                    type="date"
                    dateOptions={{
                        type: "Date"
                    }}
                    error={error?.beginError}
                    errorMessage="Beginn der Intervallrechnung muss zumindest morgen sein"
                />
                <FormRow
                    value={intervall?.validUntil}
                    /* @ts-ignore */
                    onChange={(val) => { setIntervall((old: IIntervallDTO) => ({ ...old, validUntil: val })); }}
                    label="Intervallabrechnung durchführen bis (optional: bis gekündigt)"
                    type="date"
                    dateOptions={{
                        type: "Date"
                    }}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <FormRow
                    value={intervall?.nextBillingDate}
                    /* @ts-ignore */
                    onChange={(val) => setIntervall((old: IIntervallDTO) => ({ ...old, nextBillingDate: val }))}
                    label="Nächste Abrechnung"
                    type="date"
                    dateOptions={{
                        type: "Date"
                    }}
                    error={error?.beginError}
                    errorMessage="Nächste Abrechnung kann nicht in der Vergangenheit liegen"
                />
            </div>
            <div className='form-row-label'>
                <span className="label">
                    Notiz:
                </span>
                <MultiLineInputField
                    placeholder="Notiz erstellen"
                    value={(intervall?.Note ?? "")}
                    onChangeHandler={(val) => setIntervall((old: IIntervallDTO) => ({ ...old, Note: val }))}
                />
            </div>
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Aktiv</span>
                <span>
                    <Android12Switch color="secondary" checked={!intervall?.active ? false : intervall.active} onChange={(e, val) => setIntervall((oldVal: IIntervallDTO) => ({ ...oldVal, active: val }))} />
                </span>
            </div>
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Im Voraus verrechnen</span>
                <span>
                    <Android12Switch color="secondary" checked={intervall?.inAdvance} onChange={(e, val) => setIntervall((oldVal: IIntervallDTO) => ({ ...oldVal, inAdvance: val }))} />
                </span>
            </div>
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Automatisch per E-Mail senden</span>
                <span>
                    <Android12Switch color="secondary" checked={intervall?.sendEmailAutomatically === undefined ? false : intervall.sendEmailAutomatically} onChange={(e, val) => setIntervall((oldVal: IIntervallDTO) => ({ ...oldVal, sendEmailAutomatically: val }))} />
                </span>
            </div>
            {intervall?.sendEmailAutomatically &&
                <>
                    <FormRow
                        label="E-Mail Betreff"
                        placeholder="Betreff eingeben"
                        type="text"
                        value={intervall?.emailSubject ?? intervall.title}
                        onChange={(val: string) => setIntervall((prev: IIntervallDTO) => ({ ...prev, emailSubject: val }))}
                    //error={error?.titleError}
                    //errorMessage={InputFieldErrorMessages.TitleError}
                    />
                    <FormRow
                        label="E-Mail Text"
                        placeholder="EMail-Text eingeben"
                        type="multiline"
                        value={intervall?.emailBody ?? TextTemplates.EMailText.replace("[Name]", companyData.name)}
                        onChange={(val: string) => setIntervall((prev: IIntervallDTO) => ({ ...prev, emailBody: val }))}
                    //error={error?.titleError}
                    //errorMessage={InputFieldErrorMessages.TitleError}
                    />
                </>
            }
            <div className='form-row-label'>
                <span className="label">
                    Intervallpositionen:
                </span>
                {intervall?.subscriptionPositions?.map((subscriptionPosition: IIntervallPosition, index: number) => {
                    return (
                        <SubscriptionPositionRow mode={mode} setIntervall={setIntervall} intervall={intervall} intervallPosition={subscriptionPosition} intervallPositions={intervall.subscriptionPositions} generatePdf={() => { }} setIntervallPositions={(newSubscriptionPositions: Array<IIntervallPosition>) => { setIntervall((old: IIntervallDTO) => ({ ...old, subscriptionPositions: newSubscriptionPositions })) }} backgroundColor="white"></SubscriptionPositionRow>
                    )
                })}
                <CTAButton onClickHandler={() => { setOpen(true); }} title="Rechnungsposition hinzufügen" style={{ width: "40%" }}></CTAButton>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "1em" }}>
                    <CTAButton secondary={false} onClickHandler={handleGeneratePreview} title={<><Visibility style={{ marginRight: "0.33em" }} /> Vorschau generieren</>} style={{ width: "40%" }}></CTAButton>
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "1em" }}>
                    <CTAButton secondary={false} onClickHandler={handleSendPreview} title={<><Mail style={{ marginRight: "0.33em" }} /> Vorschau an mich senden</>} style={{ width: "40%" }}></CTAButton>
                </div>
            </div>
            <PdfPreviewDialog open={previewOpen} setOpen={(val: boolean) => { setPreviewOpen(val); setPreviewDocument(undefined); }} document={previewDocument} />
            <EditCreateIntervallPositionDialog index={intervall?.subscriptionPositions?.length ?? 0} mode={mode === "createNewIntervall" ? "createNewIntervall" : "create"} intervall={intervall} setIntervall={setIntervall} open={open} setOpen={setOpen}></EditCreateIntervallPositionDialog>
            <ResetFormButton title="Felder zurücksetzen" showIcon onClickHandler={() => handleReset()} />
        </form>
    </>
}

export default EditCreateIntervallForm
import { AddRounded } from '@mui/icons-material'
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { ITaskDTO } from '../../Interfaces/Task'
import TaskExpandable from '../Task/TaskExpandable'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { taskFormModes } from '../../Pages/Aufgaben/Aufgaben'
import { blankcard } from '../ServiceCard/ServiceCard'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../UI/CardNew'
import TaskFilter, { filterTaskList, taskCardMode, TaskFilters } from './TaskFilter'

export type ITaskSelectMode = "select" | "normal"

export type GroupedTasks = Record<number, ITaskDTO[]>

interface ITaskCard extends blankcard {
  showOnlyOwnTasks?: boolean
  setClickedTask?: Dispatch<SetStateAction<ITaskDTO>>
  setFormMode?: Dispatch<SetStateAction<taskFormModes>>
  openModalOnTaskClick?: boolean
  right?: (props: { expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>> }) => ReactNode
  cardMode?: "task" | "project"
  setCardMode?: Dispatch<SetStateAction<"task" | "project">>
}



//TODO: refactor passed props
function TaskCard({ height = "100%", width = "100%", setClickedTask, setFormMode, openModalOnTaskClick = true }: ITaskCard) {

  const { allTasksUnformated } = useTasks()

  const { changeVisibility } = useModals()

  const [mode, setMode] = useState<ITaskSelectMode>("normal")

  const { user } = useUser();

  const [filteredTasks, setFilteredTasks] = useState<GroupedTasks>([]);
  const [cardMode, setCardMode] = useState<taskCardMode>(taskCardMode.showOwnTasks);

  const cardContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onTaskFilterChange({cardMode});
  }, [allTasksUnformated])

  const onTaskFilterChange = (filters: TaskFilters) => {
    if (allTasksUnformated) {
      filterTaskList(allTasksUnformated, setFilteredTasks, filters, user);
    }
  }

  return (
    <Card style={{ height: height }}>
      <CardHeader className='justify-between'>
        <CardTitle>{cardMode === taskCardMode.showOwnTasks ? "Meine Aufgaben" : cardMode === taskCardMode.showAllTaks ? "Alle Aufgaben" : "Fertiggestellte Aufgaben"}</CardTitle>
        <TaskFilter onChange={(filters) => onTaskFilterChange(filters)} setParentCardMode={setCardMode}/>
      </CardHeader>
      <CardContent ref={cardContentRef}>
        {
          Object.keys(filteredTasks).map((key, index) => (
            <TaskExpandable
              key={index}
              mode={mode}
              tasks={filteredTasks[parseInt(key)]}
              index={index}
              setClickedTask={setClickedTask}
              setFormMode={setFormMode}
              openModalOnTaskClick={openModalOnTaskClick}
              cardContentRef={cardContentRef}
            />)
          )
        }
      </CardContent>
      <CardFooter>
        <button className='font-semibold w-full h-full text-left' onClick={() => changeVisibility!("createTask", true)}>
          <AddRounded fontSize='small' /> Aufgabe erstellen
        </button>
      </CardFooter>
    </Card>

  )
}

export default TaskCard

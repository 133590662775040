import { groupBy } from "../../Hooks/Utilities/Utlitites";
import { IAppointment, IAppointmentCategory } from "../../Interfaces/Appointment";
import { ITask, ITaskDTO } from "../../Interfaces/Task";
import { AppointmentCategoryVisibilityTypes, DefaultAppointmentCategoryIds, IProjectDTO } from "./TaskContext";

export const taskReducer = (state: any, action: any) => {
    switch (action.type) {
        case "ADD_TASK":
            if (action.payload.customerOrganizationId === null) {
                action.payload.customerOrganizationId = 0;
            }

            return {
                ...state,
                tasks: groupBy!([...state.tasksUnformated, action.payload], "customerOrganizationId"),
                tasksUnformated: [...state.tasksUnformated, action.payload],
                allTasksUnformated: [...state.allTasksUnformated, action.payload]
            }
        case "UPDATE_TASK":
            let tasks = state.tasksUnformated.map((task: ITask) => task.id === action.payload.id ? { ...action.payload } : task);
            let allTasks = state.allTasksUnformated.map((task: ITask) => task.id === action.payload.id ? { ...action.payload } : task);

            tasks = tasks.filter((task: ITask) => task.aborted !== true);
            allTasks = allTasks.filter((task: ITask) => task.aborted !== true);

            tasks = tasks.map((task: ITaskDTO) => ({ ...task, customerOrganizationId: task.customerOrganizationId ?? 0 }));
            allTasks = allTasks.map((task: ITaskDTO) => ({ ...task, customerOrganizationId: task.customerOrganizationId ?? 0 }))

            return {
                ...state,
                tasks: groupBy!(tasks, "customerOrganizationId"),
                tasksUnformated: tasks,
                allTasks: groupBy!(allTasks, "customerOrganizationId"),
                allTasksUnformated: allTasks
            }
        case "SET_MONTH_INDEX":
            return {
                ...state,
                monthIndex: action.payload
            }
        case "SET_SMALL_CALENDAR_MONTH":
            return {
                ...state,
                smallCalendarMonth: action.payload
            }

        case "SET_SELECTED_DAY":
            return {
                ...state,
                selectedDay: action.payload
            }
        case "ADD_APPOINTMENT":
            if (action.payload.visibility === AppointmentCategoryVisibilityTypes.Organization && action.payload.appointmentCategory == null) {
                action.payload.appointmentCategory = DefaultAppointmentCategoryIds.OrganizationNoCategory
            }
            if (action.payload.visibility === AppointmentCategoryVisibilityTypes.Private && action.payload.appointmentCategory == null) {
                action.payload.appointmentCategory = DefaultAppointmentCategoryIds.PrivateNoCategory
            }

            return {
                ...state,
                appointments: [...state.appointments, action.payload]
            }
        case "SET_APPOINTMENTS":
            return {
                ...state,
                appointments: action.payload.map((appointment: IAppointment) => {

                    if (!appointment.appointmentCategory) {
                        if (appointment.visibility === AppointmentCategoryVisibilityTypes.Organization) {
                            appointment.appointmentCategory = DefaultAppointmentCategoryIds.OrganizationNoCategory
                        } else {
                            appointment.appointmentCategory = DefaultAppointmentCategoryIds.PrivateNoCategory
                        }
                    }
                    return appointment
                })
            }
        case "DELETE_APPOINTMENT":
            return {
                ...state,
                appointments: state.appointments.filter((appointment: IAppointment) => appointment.id !== action.payload.id)
            }
        case "UPDATE_APPOINTMENT":
            return {
                ...state,
                appointments: state.appointments.map((appointment: IAppointment) => appointment.id === action.payload.id ? action.payload : appointment)
            }
        case "ADD_APPOINTMENTCATEGORY":
            return {
                ...state,
                appointmentCategories: [...state.appointmentCategories, action.payload]
            }
        case "DELETE_APPOINTMENTCATEGORY":
            return {
                ...state,
                appointmentCategories: state.appointmentCategories.filter((ac: IAppointmentCategory) => ac.id !== action.payload.id),
                appointments: state.appointments.map((a: IAppointment) => (a.appointmentCategory === action.payload.id)
                    ? { ...a, appointmentCategory: a.visibility === AppointmentCategoryVisibilityTypes.Organization ? DefaultAppointmentCategoryIds.OrganizationNoCategory : DefaultAppointmentCategoryIds.PrivateNoCategory }
                    : a)
            }
        case "UPDATE_APPOINTMENTCATEGORY":
            return {
                ...state,
                appointmentCategories: state.appointmentCategories.map((ac: IAppointmentCategory) => ac.id === action.payload.id ? action.payload : ac)
            }
        case "SET_APPOINTMENTCATEGORIES":
            return {
                ...state,
                appointmentCategories: [{
                    id: -1,
                    name: 'Ohne Kategorie',
                    color: '#F6EBFF',
                    visibility: AppointmentCategoryVisibilityTypes.Organization
                },
                {
                    id: -2,
                    name: 'Aufgaben',
                    color: '#ff0000a1',
                    visibility: AppointmentCategoryVisibilityTypes.Organization
                },
                {
                    id: -3,
                    name: 'Urlaub',
                    color: '#32b13d',
                    visibility: AppointmentCategoryVisibilityTypes.Organization
                },
                {
                    id: -4,
                    name: 'Ohne Kategorie',
                    color: '#ADD8E6',
                    visibility: AppointmentCategoryVisibilityTypes.Private
                },
                ...action.payload]
            }
        case "SET_TASKS":
            let unfinishedTasks = action.payload.filter((task: ITaskDTO) => {
                if ((task.finished === undefined || task.finished !== true) && (task.aborted !== true)) {
                    return true;
                } else {
                    return false;
                }
            });

            action.payload = action.payload.map((task: ITaskDTO) => ({ ...task, customerOrganizationId: task.customerOrganizationId ?? 0 }))
            unfinishedTasks = unfinishedTasks.map((task: ITaskDTO) => ({ ...task, customerOrganizationId: task.customerOrganizationId ?? 0 }));

            return {
                ...state,
                tasks: groupBy!([...unfinishedTasks], "customerOrganizationId"),
                tasksUnformated: [...unfinishedTasks],
                allTasks: groupBy!([...action.payload], "customerOrganizationId"),
                allTasksUnformated: [...action.payload],
                finishedTasks: groupBy!([...action.payload.filter((task: ITaskDTO) => task.finished === true)], "customerOrganizationId")
            }
        case "DELETE_TASK":
            const remainingTasks = state.tasksUnformated.filter((task: ITaskDTO) => {
                if (task.id !== action.payload.id) {
                    return true;
                } else {
                    return false;
                }
            })

            const allRemainingTasks = state.allTasksUnformated.filter((task: ITaskDTO) => {
                if (task.id !== action.payload.id) {
                    return true;
                } else {
                    return false;
                }
            })

            return {
                ...state,
                tasks: groupBy!([...remainingTasks], "customerOrganizationId"),
                tasksUnformated: [...remainingTasks],
                allTasksUnformated: [...allRemainingTasks],
                allTasks: groupBy!([...allRemainingTasks], "customerOrganizationId")
            }
        case "FINISH_TASK":
            const newTasks = state.tasksUnformated.map((task: ITaskDTO) => {
                if (task.id === action.payload.id) {
                    return { ...task, finished: true };
                } else {
                    return task;
                }
            })

            const statetasks = state.tasksUnformated.filter((task: ITaskDTO) => {
                if (task.id === action.payload.id) {
                    return false;
                }
                return true;
            });

            return {
                ...state,
                tasks: groupBy!([...statetasks], "customerOrganizationId"),
                tasksUnformated: [...statetasks],
                allTasks: groupBy!([...newTasks], "customerOrganizationId"),
                allTasksUnformated: [...newTasks]
            }
        case "ADD_SELECTED_TASK":
            return {
                ...state,
                selectedTasks: [...state.selectedTasks, action.payload]
            }
        case "REMOVE_SELECTED_TASK":
            return {
                ...state,
                selectedTasks: state.selectedTasks.filter((task: ITask) => task.id !== action.payload.id)
            }
        case "RESET_SELECTED_TASKS":
            return {
                ...state,
                selectedTasks: []
            }
        case "SET_DELIVERYNOTES":
            return {
                ...state,
                deliveryNotes: action.payload
            }
        case "ADD_DELIVERYNOTE":
            return {
                ...state,
                deliveryNotes: [...state.deliveryNotes, action.payload]
            }
        case "UPDATE_DELIVERYNOTE":
            return {
                ...state,
                deliveryNotes: state.deliveryNotes?.map((deliveryNote: any) => {
                    if (deliveryNote.id === action.payload.id) {
                        return action.payload;
                    }
                    return deliveryNote;
                })
            }
        case "DELETE_DELIVERYNOTE":
            return {
                ...state,
                deliveryNotes: state.deliveryNotes?.filter((deliveryNote: any) => {
                    if (deliveryNote.id === action.payload.id) {
                        return false;
                    }
                    return true;
                })
            }
        case "ADD_DELIVERYNOTEPOSITION":
            return {
                ...state,
                deliveryNotes: state.deliveryNotes.map((element: any) => {
                    if (element.id === action.payload.deliveryNoteId) {
                        return { ...element, positions: [...element.positions, action.payload] }
                    }
                    return element
                })
            }
        case "UPDATE_DELIVERYNOTEPOSITION":
            return {
                ...state,
                deliveryNotes: state.deliveryNotes.map((element: any) => {
                    if (element.id === action.payload.deliveryNoteId) {
                        return {
                            ...element, positions: element.positions.map((position: any) => {
                                if (position.id === action.payload.id) {
                                    return action.payload
                                }
                                return position
                            })
                        }
                    }
                    return element
                })
            }
        case "DELETE_DELIVERYNOTEPOSITION":
            return {
                ...state,
                deliveryNotes: state.deliveryNotes.map((element: any) => {
                    if (element.id === action.payload.deliveryNoteId) {
                        return {
                            ...element, positions: element.positions.filter((position: any) => {
                                console.log("ELEMENT", position, "action.payload", action.payload)
                                return position.id !== action.payload.id
                            })
                        }
                    }
                    return element
                })
            }
        case "ADD_PROJECT":
            return {
                ...state,
                projects: [...state.projects, action.payload]
            }
        case "SET_PROJECTS":
            return {
                ...state,
                projects: action.payload
            }
        case "UPDATE_PROJECT":
            return {
                ...state,
                projects: state.projects.map((project: IProjectDTO) => {
                    if (project.id === action.payload.id) {
                        return action.payload
                    }
                    return project;
                })
            }
        case "DELETE_PROJECT":
            return {
                ...state,
                projects: state.projects.filter((project: IProjectDTO) => {
                    if (project.id !== action.payload.id) {
                        return project;
                    }
                })
            }
        default:
            return {
                ...state
            }
    }
}
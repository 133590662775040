import { useEffect, useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { useMobileView } from '../../Hooks/useMobileView'

import SummaryCard from '../../Components/Kassa/SummaryCard'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import { IProductDTO } from '../../Contexts/ProductContext/ProductProvider'
import { IBillDTO } from '../../Interfaces/Bill'
import { Kassenbon } from './KassaVerifizierung'

function Kassa() {

    const mobile = useMobileView()

    const { defaultTask } = useTasks();

    const [chosenProducts, setChosenProducts] = useState<Array<IProductDTO>>([]);

    const [bill, setBill] = useState<IBillDTO>({});

    const addChosenProducts = (clickedProduct: IProductDTO) => {
        let product = chosenProducts.find((temp: IProductDTO) => temp.id === clickedProduct.id);

        if (product) {
            setChosenProducts((old) => old.map((temp: IProductDTO) => {
                if (clickedProduct.id === temp.id) {
                    return { ...temp, count: temp.count! + 1 }
                }
                return temp;
            }))
        } else {
            setChosenProducts((old) => ([...old, { ...clickedProduct, count: 1 }]))
        }
    }

    const decrementChosenProducts = (clickedProduct: IProductDTO) => {
        let product = chosenProducts.find((temp: IProductDTO) => temp.id === clickedProduct.id);

        if (product?.count! > 1) {
            setChosenProducts((old) => old.map((temp: IProductDTO) => {
                if (clickedProduct.id === temp.id) {
                    return { ...temp, count: (temp.count!) >= 1 ? (temp.count! - 1) : 0 }
                }
                return temp;
            }))
        } else {
            setChosenProducts((old) => (old.filter(temp => temp.id !== clickedProduct.id)))
        }
    }

    useEffect(() => { console.log(chosenProducts) }, [chosenProducts])

    const { registrierkasse, registrierkasseZertifikat, registrierkasseDatenerfassungsprotokoll } = useBills();
    const { companyData } = useCompanyData();

    return (
        <>
            <CardColumn
                height='100%'
                width='75%'
            >
                <SummaryCard
                    height='100%'
                    width='100%'
                    chosenProducts={chosenProducts}
                    setChosenProducts={setChosenProducts}
                    addChosenProducts={addChosenProducts}
                    decrementChosenProducts={decrementChosenProducts}
                    bill={bill}
                    setBill={setBill}
                />
            </CardColumn>
            <CardColumn
                height='100%'
                width='25%'
            >
                {registrierkasseZertifikat &&
                    <Kassenbon
                        zertifikat={registrierkasseZertifikat}
                        companyData={companyData}
                        products={chosenProducts}
                        setProducts={setChosenProducts}
                        kasse={registrierkasse}
                        beleg={{ belegTyp: "Standardbeleg" }}
                        disabled={false}
                    />
                }
            </CardColumn>
        </>
    )
}

export default Kassa
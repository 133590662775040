import { IconButton, Tooltip } from "@mui/material"
import { IProductDTO } from "../../Contexts/ProductContext/ProductProvider"
import { Input } from "../UI/Input"
import { Dispatch, SetStateAction } from "react"
import { Minus, Plus, Trash } from "lucide-react"

type ScannedProductRowProps = {
    product: IProductDTO
    setProductCount: (count: number) => void
    removeProduct: (product: IProductDTO) => void
    focusRef: React.RefObject<HTMLDivElement>
    errorMessage?: string
}

export default function ScannedProductRow({ product, setProductCount, removeProduct, focusRef, errorMessage }: ScannedProductRowProps) {

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") { //prevent extra click on IconButtons from Barcode-Scanner input if it includes \n
            e.preventDefault();
        }
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center w-full gap-2" >
                <span className="flex-1">{product.title}</span>
                <Input className="w-[10%] min-w-[60px] text-center" type="number" value={product.count} onChange={(e) => setProductCount(parseInt(e.target.value))} />
                <IconButton onKeyDown={handleKeyDown} onClick={incrementProductCount}><Plus color="green" /></IconButton>
                <IconButton onKeyDown={handleKeyDown} onClick={decrementProductCount}><Minus color="red" /></IconButton>
                <Tooltip title="Produkt entfernen"><IconButton onKeyDown={handleKeyDown} onClick={() => removeProduct(product)}><Trash color="red" /></IconButton></Tooltip>
            </div>
            <span className="text-red-500 text-xs">{errorMessage}</span>
        </div>

    )

    function incrementProductCount() {
        if (!product.count) {
            setProductCount(1);
            focusRef.current?.focus();
            return;
        }
        setProductCount(product.count + 1);
        focusRef.current?.focus();
    }

    function decrementProductCount() {
        if (!product.count || product.count <= 1) { focusRef.current?.focus(); return; }
        setProductCount(product.count - 1);
        focusRef.current?.focus();
    }
}
import { Button, CircularProgress } from '@mui/material'
import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import styles from './CTAButton.module.css'

type backgroundColors = "error" | "base"

function CTAButton({ startIcon, endIcon, color, variant, onClickHandler, title, secondary = true, backgroundColor, disabled, style, isLoading }: { color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning', variant?: 'outlined' | 'contained' | 'text', endIcon?: any, startIcon?: any, onClickHandler: () => void, title: ReactNode, secondary?: boolean, backgroundColor?: backgroundColors, disabled?: boolean, style?: React.CSSProperties, isLoading?: boolean }) {


  const [colorL, setColor] = useState<{ color: string, light: string } | undefined>(undefined)

  useLayoutEffect(() => {
    switch (backgroundColor) {
      case "error":
        setColor({
          color: "var(--error)",
          light: "var(--error-light)"
        })
        break
      case "base":
        setColor({
          color: "var(--text-contrast)",
          light: "var(--light)"
        })
        break
      case "error":
        setColor({
          color: "var(--text-contrast)",
          light: "var(--light)"
        })
        break

    }
  }, [])

  if (variant != undefined && color != undefined) {
    return <Button
      className={styles.button}
      variant={variant}
      color={color}
      style={{
        minHeight: "35px",
        ...style
      }}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled ?? false}
      onClick={onClickHandler}
    >
      {title}
    </Button>
  } else {
    return (
      <Button
        className={styles.button}
        style={{
          backgroundColor: (!secondary || disabled) ? "var(--secondary-light)" : "var(--brand-light)",
          color: (!secondary || disabled) ? "var(--secondary)" : "var(--brand)",
          minHeight: "35px",
          ...style
        }}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled ?? false}
        onClick={onClickHandler}
      >
        {isLoading ?
          <CircularProgress size={"24px"} />
          : title
        }

      </Button>
    )
  }
}

export default CTAButton
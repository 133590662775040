import { ExpandLessRounded, MoreVertRounded } from '@mui/icons-material';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { Collapse, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { IProjectDTO, useTasks } from '../../Contexts/TaskContext/TaskContext';
import { getClassNames } from '../../Hooks/Utilities/Utlitites';
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton';
import ContextMenu from '../Menu/ContextMenu';
import styles from './Project.module.css';
import YesNoDialog from '../Dialogs/YesNoDialog/YesNoDialog';
import Task from '../Task/Task';
import { useUser } from '../../Contexts/UserContext/UserContext';
import AreaRow from '../Task/AreaRow';
import EditAreaPicturesDialog from '../Dialogs/EditAreaPicturesDialog/EditAreaPicturesDialog';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useHandleDeleteProject } from '../../redux/requestHandlers/Project';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useEmployees } from '../../Contexts/EmployeeContext/EmployeeProvider';


interface IProjectRow {
    project: IProjectDTO
    index: number
    handleEdit?: (project: IProjectDTO) => void
}



const ProjectRow = ({ project, index, handleEdit }: IProjectRow) => {

    const { employees } = useEmployees();
    const { companies } = useContacts();
    const { handleDeleteProject, isLoading } = useHandleDeleteProject();

    const { allTasksUnformated } = useTasks();
    const { user } = useUser();

    const [expanded, setExpanded] = useState(false)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [anchorElProject, setAnchorElProject] = useState<HTMLElement | null>(null)

    const handleDelete = () => {
        handleDeleteProject(project);
    }

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [tasksForProject, setTasksForProject] = useState(allTasksUnformated?.filter(t => t.projectId === project.id && t.userIds?.includes(user?.userId ?? 0)));

    useEffect(() => { setTasksForProject(allTasksUnformated?.filter(t => t.projectId === project.id && t.userIds?.includes(user?.userId ?? 0))) }, [allTasksUnformated])

    useEffect(() => { console.log(project) }, [project])

    const [editAreaPicturesOpen, setEditAreaPicturesOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const options = [
        {
            text: "Bearbeiten",
            function: () => { if (handleEdit) handleEdit(project); console.log(handleEdit) },
        },
        {
            text: "Lieferschein erstellen",
            function: () => { navigate(`/aufgaben/lieferschein/erstellen?projectId=${project.id}`) },
        },
        {
            text: "Löschen",
            function: () => setShowConfirmModal(true),
        }
    ]


    useEffect(() => {
        let tempOptions = options;
        if (!user?.rights?.updateProject) {
            tempOptions = (tempOptions.filter(option => option.text !== "Bearbeiten"));
        }
        if (!user?.rights?.deleteTask) { //TODO: Create Right to create DeliveryNote
            tempOptions = (tempOptions.filter(option => option.text !== "Lieferschein erstellen"));
        }
        if (!user?.rights?.deleteTask) { //TODO: Create Right to create DeliveryNote
            tempOptions = (tempOptions.filter(option => option.text !== "Löschen"));
        }
    }, [user?.rights])


    return (
        <>
            <YesNoDialog open={showConfirmModal} onClose={() => setShowConfirmModal(false)} object={{ submitFunction: handleDelete, cancelFunction: () => setShowConfirmModal(false), modalTitle: "Projekt löschen", modalText: "Möchten Sie dieses Projekt wirklich löschen?" }} />
            {/*<div className='flex column' style={{ marginInline: "-10px", padding: "15px 0", backgroundColor: index % 2 == 1 ? "var(--ultra-light)" : "transparent" }} >
                <div className='flex centered-aligned stretched-justify' style={{ paddingRight: "10px", position: "relative" }} onClick={() => setExpanded((old) => !old)}>
                    <div className={getClassNames([styles.accentContainer, "centered"])} >
                        <div className={styles.accent} />
                    </div>
                    <div className='flex centered-justified column'>
                        <header className='flex' style={{ height: "100%", paddingLeft: "13px" }}>
                            <span style={{ fontWeight: "500" }}>{project.title}</span>
                        </header>
                        <div className='flex centered-aligned' style={{ gap: "8px" }}>
                            <div className='flex centered-aligned' style={{ paddingLeft: "13px", fontSize: "14px", gap: "5px", marginTop: "5px" }}>
                                <AccessTimeRoundedIcon color='inherit' fontSize='inherit' />
                                <span style={{ color: "var(--text-contrast)" }}>{project.scheduledDate != null ? dayjs(project.scheduledDate).format('DD.MM.YY') : "Fortlaufend"}</span>
                            </div>
                            <span>|</span>
                            <div className='flex centered' style={{ fontSize: "14px", gap: "5px", marginTop: "5px", padding: "2px 8px", borderRadius: "100px", backgroundColor: "var(--light)" }}>
                                <PeopleRoundedIcon color='inherit' fontSize='inherit' />
                                <span style={{ color: "var(--text-contrast)", fontWeight: "400" }}>{project.userIds.length}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex centered-aligned'>
                        <IconButton onClick={(e) => { e.stopPropagation(); setExpanded((old) => !old); }}>
                            {!expanded && <ExpandMoreRoundedIcon fontSize='small' />}
                            {expanded && <ExpandLessRounded fontSize='small' />}
                        </IconButton>
                        {options?.length > 0 && <>
                            <IconTooltipButton
                                arrow
                                title='Optionen'
                                style={{ marginLeft: "-5px" }}
                                onClick={(e) => setAnchorElProject(e.currentTarget)}
                            >
                                <MoreVertRounded
                                    fontSize='small'
                                />
                            </IconTooltipButton>
                            <ContextMenu
                                anchorEl={anchorElProject}
                                onClose={() => setAnchorElProject(null)}
                                options={options}
                            />
                        </>}
                    </div>
                </div>
                {(<Collapse in={expanded}>
                    <div style={{ paddingLeft: "0.25em", paddingRight: "0.25em", marginTop: "0.25em" }}>
                        <span style={{ fontWeight: 500 }}>Aufgaben</span>
                        {
                            tasksForProject?.map((task, i) => {
                                return <Task mode='normal' openModalOnTaskClick={true} index={i} organizationIndex={index} key={i} task={task} />
                            })
                        }
                        {project?.areas &&
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.25em" }}>
                                <span style={{ fontWeight: 500 }}>Bereiche</span>
                                <IconTooltipButton
                                    arrow
                                    title='Optionen'
                                    style={{ marginLeft: "-5px" }}
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                >
                                    <MoreVertRounded
                                        fontSize='small'
                                    />
                                </IconTooltipButton>
                                <ContextMenu
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    options={[
                                        {
                                            label: "Bearbeiten",
                                            onClick: () => { setEditAreaPicturesOpen(true) },
                                            selectable: false
                                        }
                                    ]}
                                />
                                <EditAreaPicturesDialog inputProject={project} isTask={false} onClose={() => { setEditAreaPicturesOpen(false); }} open={editAreaPicturesOpen} />
                            </div>
                        }
                        {
                            project?.areas?.map((area, i) => {
                                return <AreaRow index={i} area={area} project={project} />
                            })
                        }

                    </div>
                </Collapse>)}

            </div >*/}
            <div className={`rounded ${index % 2 === 1 ? "bg-default-contrast" : ""}`}>
                <div className={`flex flex-row justify-between items-center p-2`}>
                    <div className='flex flex-row items-center gap-2'>
                        <IconButton onClick={() => navigate(`/aufgaben/projekt/${project.id}`)}>
                            <GridViewRoundedIcon
                                sx={{
                                    color: `var(--brand)`
                                }}
                                fontSize='large' />
                        </IconButton>
                        <div className='flex flex-col gap-1'>
                            <div className='flex flex-row gap-2 flex-wrap justify-between'>
                                <span className='font-medium text-lg'>{project.title}</span>
                                <span className='flex items-center gap-1 justify-center'>
                                    <Tooltip title="Anzahl Mitarbeiter">
                                        <PeopleRoundedIcon fontSize='medium' />
                                    </Tooltip>
                                    {project.userIds.length}
                                </span>
                            </div>
                            <div className='flex flex-row gap-1'>
                                <div>
                                    <Tooltip title='Projektstart'>
                                        <HourglassTopIcon fontSize='inherit' sx={{ color: `gray` }} />
                                    </Tooltip>
                                    <span className='text-sm'>{dayjs(project.startDate).format('DD.MM.YYYY')}</span>
                                </div>
                                <div>-</div>
                                <div>
                                    <Tooltip title='Geplante Fertigstellung'>
                                        <HourglassBottomIcon fontSize='inherit' sx={{ color: `gray` }} />
                                    </Tooltip>
                                    <span className='text-sm'>{project.scheduledDate ? dayjs(project.scheduledDate).format('DD.MM.YYYY') : "Fortlaufend"}</span>
                                </div>
                            </div>
                            <div className='text-sm'>
                                {project.customerOrganizationId ?
                                    <span>{companies.find((company => company.id === project.customerOrganizationId))?.name}</span> :
                                    <span className='italic'>Kein Kunde zugewiesen</span>}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row items-center flex-wrap justify-center'>
                        {expanded ? <IconButton onClick={() => setExpanded(false)}><ExpandLessRounded /> </IconButton> : <IconButton onClick={() => setExpanded(true)}><ExpandMoreRoundedIcon /></IconButton>}
                        <MoreOptionsButton options={options} />
                    </div>
                </div>

                <div className={`${expanded ? "border-t border-gray-300" : ""}`}>
                    <div className="flex flex-col ml-16">
                        <Collapse in={expanded}>
                            {project.description &&
                                <div>
                                    <h3 className="font-medium">Projektbeschreibung:</h3>
                                    {project.description}
                                </div>
                            }
                            <div>
                                <h3 className='font-medium'>Mitarbeiter:</h3>
                                {project.userIds.map((userId, i) => (
                                    <>
                                        {employees.find((employee) => employee.id === userId)?.username}
                                        {i < project.userIds.length - 1 ? ", " : ""}
                                    </>
                                ))}
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ProjectRow
import { Label, Pie, PieChart } from "recharts";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../UI/Chart";
import { useParams } from "react-router-dom";
import { useGetEmployeeDataSummaryForProjectQuery } from "../../redux/apiSlices/Project";
import { useEffect, useState } from "react";

export default function ProjectEmployeeHoursDistributionChart() {

    const { id } = useParams();

    const { data: chartData, isLoading, isFetching, refetch } = useGetEmployeeDataSummaryForProjectQuery(id ? parseInt(id) : 0, { refetchOnMountOrArgChange: 2 });
    const [chartConfig, setChartConfig] = useState<ChartConfig>();
    const [totalHours, setTotal] = useState(0);

    useEffect(() => {
        if (chartData) {
            setChartConfig(
                chartData?.reduce((acc, curr, index) => {
                    acc[curr.userId] = {
                        label: curr.name,
                        color: `hsl(var(--chart-${index+1}))`,
                    };
                    return acc;
                }, {} as ChartConfig) || {}
            );

        }
    }, [chartData]);

    useEffect(() => {
        if (chartData) {
            setTotal(chartData.reduce((acc, curr) => acc + curr.hours, 0));
        }
    }, [chartData])

    return (
        <>
            {chartConfig && chartData && chartData.length > 0 &&
                <ChartContainer
                    config={chartConfig}
                    className="mx-auto aspect-square max-h-[500px]"
                >
                    <PieChart>
                        <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent hideLabel />}
                        />
                        <Pie
                            data={chartData}
                            dataKey="hours"
                            nameKey="name"
                            innerRadius={60}
                            strokeWidth={5}
                        >
                            <Label
                                content={({ viewBox }) => {
                                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                        return (
                                            <text
                                                x={viewBox.cx}
                                                y={viewBox.cy}
                                                textAnchor="middle"
                                                dominantBaseline="middle"
                                            >
                                                <tspan
                                                    x={viewBox.cx}
                                                    y={viewBox.cy}
                                                    className="fill-foreground text-3xl font-bold"
                                                >
                                                    {" " + totalHours.toLocaleString()}
                                                </tspan>
                                                <tspan
                                                    x={viewBox.cx}
                                                    y={(viewBox.cy || 0) + 24}
                                                    className="fill-muted-foreground"
                                                >
                                                Gesamtstunden
                                                </tspan>
                                            </text>
                                        )
                                    }
                                }}
                            />
                        </Pie>
                    </PieChart>
                </ChartContainer>}
                {chartData && chartData.length === 0 && <div className="text-center h-full flex justify-center items-center">Diesem Projekt wurden noch keine Leistungen zugeordnet</div>}
        </>
    )
}
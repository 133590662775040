import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextareaAutosize } from "@mui/material";
import { useState } from "react";
import styles from './InputField.module.css';
import { FieldValue, UseFormRegister, UseFormRegisterReturn } from "react-hook-form";

export type InputFieldTypes = "password" | "multiline" | "text" | "number" | "date" | "switch" | "select" | "time";
type InputFieldErrorStyles = "small" | "medium"

export interface IInputField {
    value: any
    onChange: (value: any) => void
    onBlurHandler?: (value: any) => void
    label?: string
    placeholder?: string
    error?: boolean
    errorMessage?: string
    errorStyle?: InputFieldErrorStyles
    autofocus?: boolean
    step?: string
    autocomplete?: boolean
    readonly?: boolean
    type: InputFieldTypes
    maxLength?: number
    style?: any
    newPassword?: boolean
    tooltip?: boolean   //only implemented for switch at the moment!
    tooltipMessage?: string
    disabled?: boolean

    //react-hook-form
    register?: UseFormRegisterReturn

}

export enum InputFieldErrorMessages {
    CustomerRequired = "Bitte wählen Sie einen Kunden aus.",
    EmployeeRequired = "Bitte wählen Sie einen Mitarbeiter aus.",
    NameRequired = "Bitte geben Sie einen Namen an.",
    EmailError = "Bitte geben Sie eine gültige E-Mail-Adresse an.",
    TitleError = "Bitte geben Sie einen Titel an.",
    StartDateError = "Startzeitpunkt muss vor dem Endzeitpunkt liegen.",
    OrganizationRoleError = "Bitte eine Berechtigungsrolle auswählen",
    UsernameError = "Bitte geben Sie einen Nutzernamen mit mindestens 5 Zeichen ein",
    EMailError = "Bitte geben Sie eine E-Mail ein, diese muss ein @ beinhalten",
    PasswordError = "Bitte geben Sie ein Passwort ein, dass aus mindestens 8 Zeichen besteht",
    PasswordNotMatchError = "Die Passwörter stimmen nicht überein",
    BillPrefixRequired = "Bitte wählen Sie einen Rechnungskreis aus",
    BillNumberRequired = "Bitte wählen Sie einen Rechnungskreis aus und/oder geben Sie eine Rechnungsnummer an",
    BillNumberLoadingError = "Bitte warten Sie bis die nächste Rechnungsnummer geladen wird",
    OfferPrefixRequired = "Bitte wählen Sie einen Angebotskreis aus",
    OfferNumberRequired = "Bitte wählen Sie einen Angebotskreis aus und/oder geben Sie eine Angebotsnummer an",
    OfferNumberLoadingError = "Bitte warten Sie bis die nächste Angebotsnummer geladen wird",
    JournaltextError = "Bitte geben Sie einen Journaltext ein",
    JournaltextTooLongError = "Journaltext darf maximal 2500 Zeichen lang sein",
    StreetRequiredError = "Bitte geben Sie eine Straße an",
    HousenumberRequiredError = "Bitte geben Sie eine Hausnummer an",
    PostalcodeRequiredError = "Bitte geben Sie eine Postleitzahl an",
    CityRequiredError = "Bitte geben Sie eine Stadt an",
    TaxNumberRequiredError = "Bitte geben Sie Ihre Steuernummer an",
    CourtNameRequiredError = "Bitte geben Sie Ihr zuständiges Firmengericht an",
    BankNameRequiredError = "Bitte geben Sie den Namen Ihrer Bank an",
    IBANRequiredError = "Bitte geben Sie Ihren IBAN an",
    BICRequiredError = "Bitte geben Sie Ihren BIC an",
    PrintLabelError = "Bitte geben Sie vor dem Drucken gütlige Etiketten-Dimension an (Minimum 10x10)",
    PhoneCardMissedCallMailError = "Der ausgewählten Person/Organisation wurde noch keine E-Mail-Adresse zugewiesen",
    YearMonthRequired = "Bitte wählen Sie ein Monat aus",
    VisibilityRequired = "Bitte wählen Sie eine Sichtbarkeit aus",
    ValueRequiredError = "Bitte geben Sie einen gültigen Wert an"
}

function InputFieldRefactored({ disabled, value, type, onChange, onBlurHandler, label, placeholder, error, errorMessage, errorStyle, autofocus, autocomplete, readonly, step, maxLength, style, newPassword, register }: IInputField) {

    const [showPassword, setShowPassword] = useState(false);

    if (type === "multiline") {
        return (
            <div className={styles.wrapper.concat(" flex column start")} style={style}>
                {label && (<span className="label">
                    {label}
                </span>)}
                <div className={error ? styles.containerError.concat(" flex centered stretched-justify") : styles.container.concat(" flex centered stretched-justify")} style={style}>
                    <TextareaAutosize
                        {...register}
                        disabled={disabled}
                        maxLength={maxLength}
                        autoFocus={autofocus ?? false}
                        placeholder={placeholder ?? ""}
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        minRows={5}
                        style={{
                            width: "100%",
                            fontFamily: "inherit",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            lineHeight: 1
                        }}
                        readOnly={readonly}
                    />
                </div>
                {error && <span className={errorStyle === "medium" ? styles.errorLogin : styles.error}>{errorMessage}</span>}
            </div>
        )
    }
    else if (type === "password") {
        return (
            <div className={styles.wrapper.concat(" flex column")}>
                {label && (<span className="label">
                    {label}
                </span>)}
                <div className={error ? styles.containerError.concat(" flex centered stretched-justify") : styles.container.concat(" flex centered stretched-justify")}>
                    <input
                        disabled={disabled}
                        readOnly={readonly}
                        autoComplete={newPassword ? "new-password" : (autocomplete ? "on" : "off")}
                        onBlur={(e) => { onBlurHandler?.(e.target.value) }}
                        autoFocus={autofocus ?? false}
                        placeholder={"Passwort"}
                        type={!showPassword ? "password" : "text"}
                        onChange={(e) => { onChange(e.target.value) }}
                        value={value}
                        style={{ width: "100%" }}
                    />
                    <button tabIndex={-1} className={styles.button.concat(" flex centered pointer")}
                        onClick={(e) => { e.preventDefault(); setShowPassword((prevVal => !prevVal)) }}>
                        {showPassword ? (
                            <VisibilityOff fontSize="small" />
                        ) : (
                            <Visibility fontSize='small' />
                        )}
                    </button>
                </div>
                {error && <span className={errorStyle === "medium" ? styles.errorLogin : styles.error}>{errorMessage}</span>}
            </div>
        )
    } else {
        const inputStyle = {
            color: error ? "red" : "black",
            "::placeholder": {
                color: error ? "red" : "gray"
            }
        }

        return (
            <div className={styles.wrapper.concat(" flex column")}>
                {label && (<span className="label">
                    {label}
                </span>)}
                <div className={error ? styles.containerError.concat(" flex centered stretched-justify") : styles.container.concat(" flex centered stretched-justify")}>
                    <input
                        disabled={disabled}
                        {...register}
                        readOnly={readonly}
                        autoFocus={autofocus}
                        autoComplete={autocomplete ? "on" : "off"}
                        placeholder={placeholder}
                        type={type}
                        step={step}
                        onChange={(e) => { onChange(e.target.value) }}
                        value={value ?? ""}
                        //@ts-ignore
                        style={{ width: "100%", textAlign: type === "number" ? "right" : "left" }}
                        maxLength={maxLength}
                    />
                </div>
                {error && <span className={errorStyle === "medium" ? styles.errorLogin : styles.error}>{errorMessage}</span>}
            </div>
        )
    }
}

export default InputFieldRefactored
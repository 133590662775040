import React, { ReactNode, useState } from 'react'

import { AddRounded } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { IProjectDTO, defaultProjectDTO } from '../../Contexts/TaskContext/TaskContext'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { card } from '../../Interfaces/Card'
import { useGetAllProjectsQuery } from '../../redux/apiSlices/Project'
import EditCreateProjectModal from '../Dialogs/EditCreateProject/EditCreateProjectModal'
import ProjectRow from '../Project/ProjectRow'
import { Card, CardContent, CardFooter, CardFooterButton, CardHeader, CardTitle } from '../UI/CardNew'

interface IProjectCard extends card {
  right?: (props: { expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>> }) => ReactNode
  showOnlyOwnProjects?: boolean
}

const ProjectCard = ({ width, height, order, right, showOnlyOwnProjects = false }: IProjectCard) => {

  const { data: projects, isLoading } = useGetAllProjectsQuery();
  const { user } = useUser();

  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  const [projectToEdit, setProjectToEdit] = useState<IProjectDTO>(defaultProjectDTO);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);

  const handleEdit = (project: IProjectDTO) => {
    setProjectToEdit(project);
    setShowEditProjectModal(true);
  }

  const closeEditModal = () => {
    setShowEditProjectModal(false);
    setProjectToEdit(defaultProjectDTO);
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Projekte</CardTitle>
      </CardHeader>
      <CardContent>
        {
          isLoading ? <div className="flex w-full h-full justify-center items-center"><CircularProgress /></div> :

            projects && projects.map((project, index) => {
              if (!showOnlyOwnProjects || (showOnlyOwnProjects && project.userIds.includes(user?.userId ?? 0))) {
                return <ProjectRow key={project.id} project={project} index={index} handleEdit={handleEdit} />
              } else {
                return <></>
              }
            })
        }
      </CardContent>
      <CardFooter>
        <CardFooterButton onClick={() => setShowCreateProjectModal(true)}><AddRounded fontSize='small' />Projekt erstellen</CardFooterButton>
      </CardFooter>
      <EditCreateProjectModal open={showCreateProjectModal} onClose={() => setShowCreateProjectModal(false)} />
      <EditCreateProjectModal open={showEditProjectModal} onClose={() => closeEditModal()} object={projectToEdit} mode="edit" />
    </Card>
  )
}

export default ProjectCard
import { TextModuleDocumentType } from "../../redux/apiSlices/TextModule";

export function convertTextModuleTypeString(type: TextModuleDocumentType) {
    switch (type) {
        case "Bill":
            return "Rechnung";
        case "Bill-SEPA":
            return "SEPA-Rechnung";
        case "Offer":
            return "Angebot";
        case "Intervall":
            return "Intervallrechnung";
        case "Intervall-SEPA":
            return "SEPA-Intervallrechnung";
        case "Reminder":
            return "Mahnung";
        case "Reminder-FINAL":
            return "Letzte Mahnung";
        case "Other":
            return "Andere";
        default:
            return "Unbekannt";
    }
}
import { useState } from 'react'
import Card from '../../Components/Card/Card'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import DocumentHeaderRow from '../../Components/DocumentRow/DocumentHeaderRow'
import DocumentRow from '../../Components/DocumentRow/DocumentRow'
import Pagination from '../../Components/Pagination/Pagination'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import { useDocuments } from '../../Contexts/DocumentContext/DocumentContext'
import { formatBytes, getMaxSpaceOfSubscription, totalFileSizeBytes } from '../../utils/helper'

function Dokumente() {

    const { documents } = useDocuments();

    const [page, setPage] = useState<number>(0);
    const itemsPerPage = 9;

    const spaceUsed = totalFileSizeBytes(documents);

    const { companyData } = useCompanyData();

    return (
        <>
            <CardColumn
                height='100%'
                width='100%'
            >
                <Card
                    title={`Dokumente ${spaceUsed} / ${formatBytes(getMaxSpaceOfSubscription(companyData?.subscription ?? ""))}`}
                >
                    <DocumentHeaderRow />
                    {documents.map((document, index) => {
                        if (index >= (page) * itemsPerPage && index < (page + 1) * itemsPerPage) {
                            return <><DocumentRow document={document} /></>
                        }

                    })}
                    <div style={{ marginTop: "2em" }}>
                        <Pagination items={documents ?? []} itemsPerPage={itemsPerPage} page={page} setPage={setPage} />
                    </div>
                </Card>
            </CardColumn>

        </>
    )
}

export default Dokumente
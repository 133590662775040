import { useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CompanyDataCard from '../../Components/CompanyDataCard/CompanyDataCard'
import OrganisationLogoCard from '../../Components/OrganisationLogoCard/OrganisationLogoCard'
import PageTabsWrapper from '../../Components/Page/PageTabs'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'

const SettingsCompany = () => {

  const { companyData } = useCompanyData();

  const [mode, setMode] = useState<"Rectangle" | "Square">("Rectangle");

  const routes = [
    { path: "/einstellungen/privat", name: "Persönlich" },
    { path: "/einstellungen/unternehmen", name: "Unternehmen" },
    { path: "/einstellungen/standardtexte", name: "Standardtexte" }
  ]

  return (
    <>
      <PageTabsWrapper routes={routes}>
        <CardColumn
          height='100%'
          width='50%'
        >
          <CompanyDataCard
            height="100%"
          />
        </CardColumn>
        <CardColumn
          height='100%'
          width='50%'
        >
          <OrganisationLogoCard
            mode={mode}
            setMode={setMode}
            height='100%' />
        </CardColumn>
      </PageTabsWrapper>
    </>
  )
}

export default SettingsCompany
import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { ITask } from '../../Interfaces/Task'
import Card from '../Card/Card'
import Task from '../Task/Task'
import TaskExpandable from '../Task/TaskExpandable'
//import callLink from './callLink'  //C:\Users\horstkandutsch\Source\eCockpit\eCockpit NEU\src\Hooks\Utilities\callLink.tsx
import { AddRounded, CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckRounded, RefreshRounded, MoreVertRounded, Call, CallEnd, CallMissed, Email, PhoneMissed, Sms, Cancel, CheckBox } from '@mui/icons-material'
import { Button, Checkbox, Chip, FormControlLabel, Tooltip } from '@mui/material'
import { TextField } from '@mui/material'
import styles from './TaskCard.module.css'
import ErrorDialog from '../Dialogs/ErrorDialog/ErrorDialog'
import { useDeliveryNote } from '../../Contexts/DeliveryNoteContext/DeliveryNoteContext'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'
import { card } from '../../Interfaces/Card'
import CreateTaskDialog from '../Dialogs/CreateTaskDialog/CreateTaskDialog'
import { blankcard } from '../ServiceCard/ServiceCard'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import ContextMenu from '../Menu/ContextMenu'
import { Grow } from '@mui/material'
import { string } from 'zod'
import { ContactDataType, IContactData, IPerson, IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
//mport { companyDataContext} from '../../Contexts/ContactsContext/ContactsProvider'
import { useSnackBar } from '../../Hooks/useSnackBar'
import axios, { AxiosResponse } from 'axios'
import JournalEntry from '../Journal/JournalEntry'
import { journalDTO } from '../../Interfaces/Journal'
import InputField from '../InputField/InputField'
import JournalCard from '../JournalCard/JournalCard'
import { useJournal } from '../../Contexts/JournalContext/JournalProvider'
import { VariableDeclaration } from 'typescript'
import { IOrganization, IOrganizationDTO } from '../../Interfaces/Company'
import { useUser } from '../../Contexts/UserContext/UserContext'
import LiveTicker from '../LiveTicker/LiveTicker'
import useAuthConnection from '../../Hooks/useAuthConnection'
import FormRow from '../Forms/FormUtils/FormRow'
import { autoHideDurationDefault } from '../../Global/Variables'
import { errorHandler } from '../../Connection/BaseConnection'


export type ITaskSelectMode = "select" | "normal"

{/**************************************************************************************/ }
//Daten lesen! 
//Journaleintrag muss erstellt werden

export type PhoneCardModes = "person" | "organization" | "undefined"



interface EmailCard extends blankcard{
  clickedPerson?: IPerson
  clickedCompany?: IOrganization
  phoneCardMode?: PhoneCardModes
  calledPerson: IPersonDTO, setCalledPerson?: Dispatch<SetStateAction<IPersonDTO>>,
  phoneJournalTitle: string, setPhoneJournalTitle?: Dispatch<SetStateAction<string>>,
  phoneJournalEntry: string, setPhoneJournalEntry?: Dispatch<SetStateAction<string>>,
  phoneJournalTime: Date, setPhoneJournalTime?: Dispatch<SetStateAction<Date>>,
  phoneJournalDuration: number, setPhoneJournalDuration?: Dispatch<SetStateAction<number>>,
  message: string, setMessage?: Dispatch<SetStateAction<string>>
}

{/**************************************************************************************/ }



const getTimeNumber = (phoneJournalTime: Date) => {

  var anrufEnde = new Date();
  var numberOfMlSecondsEnde = anrufEnde.getTime();
  var numberOfMlSecondsStart = phoneJournalTime.getTime();
  var durationRaw = (numberOfMlSecondsEnde - numberOfMlSecondsStart) / 60000;
  var duration = Math.round(durationRaw * 100) / 100;


  return duration;
}


//Hier wird ein SME via API ausgelöst
const EmailViaAPI = async (recipient: string, message: string, connection:any, journal:boolean, clickedCompany?: IOrganization, clickedPerson?: IPerson) => {
  //console.log("######## ", link, " ######### ", userIP)

  
  //const connection = useAuthConnection();
  
      //Objekt zur Übergabe d. Parameter gestalten:
      const smsParams = {
          "email" : recipient,
          "message" : message,
          "receiverOrg" : clickedCompany,
          "receiverPers" : clickedPerson,
          "journal" : journal
      }

      //console.log("######## ", link, " ######### ", userIP)
  
  // Make API call to get CSV string
  
      connection.post("/Phone/Sms", smsParams) //damit ruft UI die API auf...
          .then((res: AxiosResponse) => {
              // Der Anruf wird ausgeführt, hier Snackbar + Journaleintrag setzen. Idee: Journaleintrag erst, wenn aufgelegt wurde? Kann das der Server melden?
              
          })
          .catch((error: any) => {
              console.error(`Error: ${error}`);
          })

};


//Wir brauchen die Empfängernummer und den Text, danach müssen wir einen Journaleintrag kreieren.
function EmailCard({ height = "100%", width = "100%", order, cta, clickedPerson, clickedCompany, phoneCardMode, setCalledPerson, phoneJournalTitle, setPhoneJournalTitle, phoneJournalEntry, setPhoneJournalEntry,
  phoneJournalTime, setPhoneJournalTime, phoneJournalDuration, setPhoneJournalDuration }: EmailCard) {
  const [modalOpen, setModalOpen] = useState(false);
  const connection = useAuthConnection();

  useEffect(() => {
    if (phoneCardMode === "person" && clickedPerson) {
      let numbers = clickedPerson.contactData?.filter((temp: IContactData) => temp.type === ContactDataType.mail);
      if (numbers && (numbers?.length ?? 0 > 0)) {
        setLink(numbers[0].value ?? "")
      }

      let mails = clickedPerson.contactData?.filter((temp: IContactData) => temp.type === ContactDataType.mail);
      if (mails && (mails?.length ?? 0 > 0)) {
        setMail(mails[0].value ?? 0)
      }
    }
    else if(phoneCardMode === "organization" && clickedCompany) {
      setLink(clickedCompany.eMail ?? "")
      setMail(clickedCompany.eMail ?? "")
    }
    else {
      setLink("");
      setMail("");
    }

  }, [phoneCardMode])

  const { user } = useUser();
  let userIP: string = "";

  if (user) {
    userIP = String(user!!.voipAddress);
  }
  else {
    userIP = "keine IP hinterlegt."
  }

  //const CreateJournalEntry = ({ object, setObject }: { object?: journalDTO | null, setObject: Dispatch<SetStateAction<journalDTO>> }) => {}

  // Erstelle einen state-Wert für den Link
  const [link, setLink] = useState('');
  const [message, setMessage] = useState<string>("");
  const [mail, setMail] = useState('');
  const [name, setName] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackBar();
  const [journal, setJournal] = useState(true);


  // Erstelle eine Funktion, die den Link auslöst
  const handleSMSClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    if (setPhoneJournalTime) setPhoneJournalTime(new Date());
    //Hier muss ich den State auf die angerufene Person setzen:

    if (setCalledPerson && clickedPerson) setCalledPerson(clickedPerson)
     //{ enqueueSnackbar("CalledPerson gesetzt: " + clickedPerson?.completeName, { variant: "info" }) }

    if (setPhoneJournalTitle && (clickedPerson || clickedCompany)) setPhoneJournalTitle("SMS an Firma/Person: " + (clickedPerson?.completeName ?? (clickedCompany?.name ?? "")));

    //if (setPhoneJournalEntry) setPhoneJournalEntry("Start des Telefongesprächs: " + phoneJournalTime.toLocaleString() + "\n ");
    //enqueueSnackbar("SMS wird gesendet. An: "  + clickedPerson?.completeName, { variant: "info" }) 
    //if (setPhoneJournalDuration) setPhoneJournalDuration(getTimeNumber(phoneJournalTime));

    try {

      if (userIP !== undefined) {

        EmailViaAPI(link, message, connection,journal, clickedCompany, clickedPerson);
        //raiseCallViaURL(link, userIP);
      }

      { enqueueSnackbar("Email wird gesendet. Von: "  + user!!.username + " an " + link, { variant: "info" }) 
      //if (setPhoneJournalEntry) setPhoneJournalEntry("Der/die TeilnehmerIn war am " + phoneJournalTime.toLocaleString() + " nicht erreichbar.");
    }
    }
    catch (error) {
      { enqueueSnackbar("Fehler beim Senden der Email: " + error, { variant: "error" }) }
      
    }
    refreshPhoneCard();
  }


  const refreshPhoneCard = () => {

    setLink("");
    setMessage("");

  }

  

  // Erstelle eine Funktion für Abbrechen
  const handleAbbrechenClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
   
    refreshPhoneCard();

    if (setCalledPerson && clickedPerson) setCalledPerson(clickedPerson)

    //if (setPhoneJournalEntry) setPhoneJournalEntry("Der/die TeilnehmerIn war am " + phoneJournalTime.toLocaleString() + " nicht erreichbar.");
    //{ enqueueSnackbar("Journaleintrag - nicht erreichbar - erstellt", { variant: "info" }) }
  }

  // Erstelle eine Funktion für "nicht erreichbar + Mail"
  const handleMail: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (setCalledPerson && clickedPerson) setCalledPerson(clickedPerson)

    connection.post("/email/sendJournalMail", null,
      {
        params: {
          "text": message,
          "organizationId": clickedCompany?.id,
          "personId": clickedPerson?.id
        }
      })

  
  }
  


  const sendJournalMail = (text: string, organizationId?: number, personId?: number) => {
    let x = enqueueSnackbar("Rückrufmail wird versandt", { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.post("/email/sendJournalMail", null,
      {
        params: {
          text,
          organizationId,
          personId
        }
      }
    )
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Email erfolgreich versendet", { variant: "success" })
      })
      .catch((error: any) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar)
      })
  }


  const dialogprovider = useModals();

  return (
    <Card
      title="No-Reply-Email Versand"
      style={{ height, width, order }}
      cta={cta ?? true}
      ctaTitle='Zum Telefonbuch hinzufügen?'
      buttonIcon={<AddRounded fontSize='small' />}

      ctaFunction={() => { dialogprovider.changeVisibility!("editCreatePersonfromPhone", true, { props: { object: { email: link }, mode: "create" } }) }}

    >
      <div className='flex column' style={{ gap: "2px" }}>
        {
          <form>
            <div>
              <span className="label">
                Kontaktperson i.d. Personenliste gewählt: {phoneCardMode === "person" ? clickedPerson?.completeName : (phoneCardMode === "organization" ? clickedCompany?.name : "")}
              </span>
            </div>
            <div>
              <div>
                <InputField
                  type="email"
                  //padding = "2px"
                  //align="center"
                  placeholder='Emailadresse eingeben oder Kontakt anklicken'
                  value={link}
                  onChangeHandler={(val) => setLink(val)}
                />
                
              </div>
              <div>
              
                <FormRow
                label='Email Text eingeben'
                onChange={(val: string) => { setMessage(val) }}   //{ setJournalToSubmit((old: journalDTO) => ({ ...old, description: val })) }}
                placeholder='Die Antwort empfangen Sie in Ihrem Emailclient.'
                value={message}
                type='multiline'
                error={false}
                errorMessage={""}
            />
            </div>
            
            
              {/* 
                <TextField id="outlined-basic" style={{ width: "75%" }} label="Telefonnummer" variant="filled" value={link} onChange={
                  (e) => setLink(e.target.value)
                }
                />*/}
              
              <div>
                <span> </span>
               
                <Tooltip title="abbrechen">
                <Button variant="contained" onClick={handleAbbrechenClick} color="inherit" style={{ width: "49%", padding: "2px", margin: "4px" }}><Cancel>abbrechen</Cancel></Button>
              </Tooltip>
              
                <Tooltip title="Email absenden">
                <Button variant="contained" onClick={handleMail} color="success" style={{ width: "49%", padding: "2px", margin: "4px" }}><Email></Email></Button>
              </Tooltip>
                
              </div>
            </div>
            <div>
              
            

            </div>

            <div>

            </div>
                
          </form>
        }
        
      </div>
    </Card>
  )
}

export default EmailCard


/*import React, { useState, useEffect, FC } from 'react'
import Card from '../Card/Card'

import { AddRounded } from '@mui/icons-material'

import { blankcard } from '../ServiceCard/ServiceCard'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { IPerson, IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useSnackBar } from '../../Hooks/useSnackBar'
import axios from 'axios'

export type ITaskSelectMode = "select" | "normal"


//Daten lesen! 
// Ich benötige hier nun die IP-Adresse des Mitarbeitertelefons & die anzurufende Telefonnummer


//Es reicht die Person übergeben zu bekommen, denn damit kann man über Person.contactData auf das Array d. Kontaktdaten zugreifen.



interface personComp {
  person: IPerson
  index: number
}

const Person = ({ person, index }: personComp) => {

  const { changeVisibility } = useModals()

  const { enqueueSnackbar } = useSnackBar()

  const { deletePerson } = useContacts()

  const [expanded, setExpanded] = useState(false)

  const handleExpand = () => {
    setExpanded((old) => !old)
  }
}





//Hier wird der Anruf ausgelöst -> altes Return: <a href={url}>Link to resource</a>   neues return: (window.open(url));
const raiseCallViaURL = async (link: string) => {

  try {
    const baseUrl = 'http://';
    const pathParts = ['192.168.190.157', '//command.htm?number=', link, '&outgoing_uri=URI'];//hier zwei Function-Calls; getMyPhoneIP & getNumberFromInput
    const url = buildUrl(baseUrl, pathParts);
    return (axios.get(url));

  }
  catch (error) {
    console.log(error);
  }

};

//Hier wird aufgelegt: string url = "http://" + MIP + "/command.htm?RELEASE_ALL_CALLS";
const endCall = () => {
  const baseUrl = 'http://';
  const pathParts = ['192.168.190.157', '/command.htm?RELEASE_ALL_CALLS'];//hier wird aufgelegt
  const url = buildUrl(baseUrl, pathParts);
  return (axios.get(url));
};


//URL-Builder zum Zusammenfügen der URL aus der Usereingabe, der IP-Adresse a.d. Datenbank & der Telefonnummer, die eingegeben wurde
//string url = "http://" + MIP + "//command.htm?number=" + telnr + "&outgoing_uri=URI";
const buildUrl = (baseUrl: string, parts: string[]) => {
  let url = baseUrl;

  for (let i = 0; i < parts.length; i++) {
    url += parts[i];
  }

  return url;
};

interface EmailCard extends blankcard { clickedPerson: IPersonDTO }

//Wir brauchen die User-IP a.d. Datenbank. Damit bauen wir uns die URL für den Auslöser des Phonecall, danach müssen wir einen Journaleintrag kreieren.
function EmailCard({ height = "100%", width = "100%", order, cta, clickedPerson }: EmailCard) {
  useEffect(() => {
    setLink(clickedPerson.contactData?.length ?? 0 > 0 ? clickedPerson.contactData![1].value ?? ' ' : ' ')
  }, [clickedPerson])

  // Erstelle einen state-Wert für den Link
  const [link, setLink] = useState('06766114603');
  const [name, setName] = useState('Name');
  const { enqueueSnackbar } = useSnackBar()

  // Erstelle eine Funktion, die den eCockpit-Mail-Editor öffnet (mit gewählter Emailadresse)
  const handleECockpitMailClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    //try{
    //raiseCallViaURL(link);
    //{enqueueSnackbar("Telefonnummer wird gewählt.", { variant: "info" })}
    //}
    //catch(error)
    {
      { enqueueSnackbar("eCockpit-Mail-Editor", { variant: "info" }) }
    }
  }

  // Erstelle eine Funktion,die den Outlook-Mail-Editor öffnet (mit gewählter Emailadresse)
  const handleOutlookClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    //endCall();
    { enqueueSnackbar("Outlook-Email-Editor", { variant: "info" }) }
    //const email="birgit.kandutsch@softwaregutachten.at"
    //return (axios.get("mailto:birgit.kandutsch@softwaregutachten.at"))

    //<EmailLink email="birgit.kandutsch@softwaregutachten.at" />
    const email = link;
    window.location.href = "mailto:" + email + "?";

  }

  interface EmailLinkProps {
    email: string;
  }

  //Funktion funktioniert nicht, bleibt zum Testen noch stehen... <a href={`mailto:${email}`}>Send an email to {email}</a>
  const EmailLink: FC<EmailLinkProps> = ({ email }) => {
    enqueueSnackbar("Outlook-Email-Funktion" + email, { variant: "info" })
    return (
      <a href={"mailto:" + email + ""}></a>
    );
  }


  return (
    <Card
      title="Email"
      style={{ height, width, order }}
      cta={cta ?? true}
      ctaTitle='Kontakt erstellen!'
      buttonIcon={<AddRounded fontSize='small' />}
    >
      <div className='flex column' style={{ gap: "2px" }}>
        {

          <form>
            <div>
              <label>
                <span>Person/Organisation:</span> {clickedPerson.completeName}
              </label>
            </div>
            <div>
              <label>
                Emailadresse: </label>
              <input value={link} onChange={
                (e) => setLink(e.target.value)
              }
              />
              <div>
                <button onClick={handleECockpitMailClick} style={{ width: "25%" }}>eCockpit-Mail</button>
                <button onClick={handleOutlookClick} style={{ width: "25%" }}>Standard-Mail</button>
              </div>
            </div>
          </form>
        }
      </div>
    </Card>
  )
}

export default EmailCard
*/
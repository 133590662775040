import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { serviceWithNames } from '../../Interfaces/Service'
import styles from './ServiceFullRow.module.css'

//Icons
import { Checkbox, Collapse, FormControlLabel } from '@mui/material';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { useSnackBar } from '../../Hooks/useSnackBar';
import { serviceFormModes } from '../../Pages/Services/Services';
import { StaffDTO, useStaff } from '../../Contexts/StaffContext/StaffProvider';
import { AccessTime, CalendarMonth, Euro, Person } from '@mui/icons-material';
import dayjs from 'dayjs';
import { IPosition, useBills } from '../../Contexts/BillContext/BillProvider';
import ServiceFullRow from './ServiceFullRow';

interface PositionFullRowProps {
    position: IPosition
    backgroundColor?: string
    selectedPositions?: Array<number>
    setSelectedPositions?: Dispatch<SetStateAction<Array<number>>>
    setFormMode?: Dispatch<SetStateAction<serviceFormModes>>
    isInnerPosition?: boolean
    index?: number
}

function PositionFullRow({ position: service, backgroundColor, selectedPositions, setSelectedPositions, setFormMode, isInnerPosition = false, index }: PositionFullRowProps) {
    const [expanded, setExpanded] = useState(false)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const { changeVisibility } = useModals()
    const { removeService } = useServices()
    const { enqueueSnackbar } = useSnackBar()

    const { defaultService } = useServices()

    const { units } = useBills();
    const { staff } = useStaff();

    const [gesamt, setGesamt] = useState<number>(0);

    useEffect(() => {
        let sum = 0;
        service.positions?.forEach((position: IPosition) => {
            sum += (position.netto! * position.quantity!);
        })
        setGesamt(sum)
    }, [service.positions])

    const checkService = () => {
        if (!(selectedPositions?.includes(service.tempId!)) && setSelectedPositions) {
            setSelectedPositions((old: Array<number>) => ([...old, service.tempId!]))
        } else if (setSelectedPositions) {
            setSelectedPositions((old: Array<number>) => (old.filter((entry: number) => entry !== service.tempId)))
        }
    }

    return (
        <div className={'flex column'} style={{ backgroundColor: backgroundColor ?? "transparent" }}>
            <header style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ width: "40%", display: "flex", alignItems: "center" }}>
                    <FormControlLabel control={
                        <>{!isInnerPosition && <Checkbox color='secondary' checked={selectedPositions?.includes(service.tempId!)} onChange={checkService} />}</>
                    } label={((index ?? -1) >= 0) ? (index! + 1) + ". " + service.title : service.title} />
                </span>
                <span style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {service.from &&
                        <>
                            <CalendarMonth fontSize='small' />
                            {" " + dayjs(service.from).format('DD.MM.YYYY')}
                        </>
                    }
                </span>
                <span style={{ width: "15%", display: "flex", flexDirection: "row" }}>
                    {service?.netto ?
                        <>
                            <Euro fontSize='small' />
                            {`${service.netto} € / ${units.find(u => u.id === service.unitId)?.title ?? `Stk.`}`}
                        </>
                        :
                        <>
                            {` Gesamt: ${gesamt?.toFixed(2)} €`}
                        </>
                    }
                </span>
                <span style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {
                        service.quantity ?
                            <>
                                <AccessTime fontSize='small' />
                                {service.quantity} {units.find(unit => unit.id === service.unitId)?.title ?? units.find(unit => unit.id === 0)?.title}
                            </>
                            :
                            <></>
                    }
                </span>
                <span style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <>{service.userId ? <><Person fontSize='small' />{staff.find(s => s.id === service.userId)?.username}</> : <></>}</>
                </span>
            </header>
            <Collapse in={true}>
                <section style={{ paddingLeft: "4%", fontSize: "14px" }}>
                    {service.positions?.map((innerPosition: IPosition, index: number) => {
                        return <><PositionFullRow position={innerPosition} isInnerPosition={true} index={index} /></>
                    })}
                </section>
            </Collapse>
        </div>
    )
}

export default PositionFullRow
import { PersonRounded } from "@mui/icons-material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { CircularProgress, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { useGetProjectQuery } from "../../redux/apiSlices/Project";
import { Card, CardContent, CardFooter, CardFooterButton, CardHeader, CardTitle } from "../UI/CardNew";
import { Progress } from "../UI/Progress";
import ProjectFinancesGraph from "./ProjectFinancesGraph";
import ProjectEmployeeHoursDistributionChart from "./ProjectEmployeeHoursDistributionChart";

export default function ProjectDashboard() {

    const { id } = useParams();
    const navigate = useNavigate();

    const { data: project, isLoading, refetch } = useGetProjectQuery(id ? parseInt(id) : 0, { refetchOnMountOrArgChange: 2 });
    const { companies } = useContacts()

    const [projectProgress, setProjectProgress] = useState(0);

    useEffect(() => {
        if (project) {
            setProjectProgress(project?.tasks ? (project.tasks.filter(task => task.finished === true).length / project.tasks.length) * 100 : 0);
        }
    }, [project])

    return (
        <>
            {isLoading ?
                <div className="flex w-full h-full justify-center items-center"><CircularProgress /></div> :
                <div className="flex flex-col w-full gap-4">
                    <Card className="max-md:min-h-[0vh] max-h-[250px]">
                        <CardHeader>
                            <CardTitle>Übersicht</CardTitle>
                        </CardHeader>
                        <CardContent className="">
                            <div className="flex flex-row w-full justify-evenly items-center flex-wrap">
                                <div>
                                    <Tooltip title="Kunde">
                                        <AssignmentIndIcon sx={{ height: 50, width: 50, color: "var(--brand)" }} />
                                    </Tooltip>
                                    <span>{project?.customerOrganizationId ? companies.find((company) => company.id === project.customerOrganizationId)?.name : "Kein Kunde zugewiesen"}</span>
                                </div>
                                <div>
                                    <Tooltip title="Abgeschlossene Aufgaben">
                                        <PlaylistAddCheckIcon sx={{ height: 50, width: 50, color: "green" }} />
                                    </Tooltip>
                                    <span>{project?.tasks?.filter(task => task.finished === true).length}</span>
                                </div>
                                <div>
                                    <Tooltip title="Offene Aufgaben">
                                        <PlaylistRemoveIcon sx={{ height: 50, width: 50, color: "gray" }} />
                                    </Tooltip>
                                    <span>{project?.tasks?.filter(task => task.finished === false).length}</span>
                                </div>
                                <div>
                                    <Tooltip title="Projektmitarbeiter">
                                        <PersonRounded sx={{ height: 50, width: 50, color: "var(--brand)" }} />
                                    </Tooltip>
                                    <span>{project?.userIds.length}</span>
                                </div>
                            </div>

                            <div className="flex flex-col gap-4 px-3 border-t">
                                <span className="font-bold text-xl w-full">Fortschritt Aufgaben</span>
                                <Progress value={projectProgress} />
                                <div className="flex flex-row w-full items-center justify-between space-x-4">
                                    <span className="flex-1 text-sm text-left">Projektstart: {dayjs(project?.startDate).format('DD.MM.YYYY')} </span>
                                    <span className="flex-none text-sm"> {isNaN(projectProgress) ? 0 : projectProgress}%</span>
                                    <span className="flex-1 text-sm text-right">Geplante Fertigstellung: {project?.scheduledDate ? dayjs(project?.scheduledDate).format('DD.MM.YYYY') : "Fortlaufend"} </span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <div className="flex flex-row w-full h-full gap-6 flex-wrap">
                        <Card className="flex-1 min-w-[300px] max-md:min-h-[0vh]">
                            <CardContent>
                                <span className="text-center font-bold text-xl">Leistungen Zusammenfassung</span>
                                <ProjectFinancesGraph />
                            </CardContent>
                            <CardFooter>
                                <CardFooterButton onClick={() => navigate(`/rechnungswesen/projektabrechnung`)}><ArrowRightAltIcon />Projektabrechnung</CardFooterButton>
                            </CardFooter>
                        </Card>
                        <Card className="flex-1 min-w-[300px]">
                            <CardContent>
                                <span className="text-center font-bold text-xl">Stunden Zusammenfassung</span>
                                <div className="flex-1 items-center justify-center">
                                    <ProjectEmployeeHoursDistributionChart />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            }
        </>
    )
}
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ITask, ITaskDTO } from '../../Interfaces/Task'
import Expandable from '../Expandable/Expandable'
import Task from './Task'

import styles from './TaskExpandable.module.css'

import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useDeliveryNote } from '../../Contexts/DeliveryNoteContext/DeliveryNoteContext'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { taskFormModes } from '../../Pages/Aufgaben/Aufgaben'
import { ITaskSelectMode } from '../TaskCard/TaskCard'

export function isIterable(obj: any) {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

function TaskExpandable({ tasks, index, mode, setClickedTask, setFormMode, openModalOnTaskClick, cardContentRef }: { tasks: ITask[], index: number, mode: ITaskSelectMode, setClickedTask?: Dispatch<SetStateAction<ITaskDTO>>, setFormMode?: Dispatch<SetStateAction<taskFormModes>>, openModalOnTaskClick?: boolean, cardContentRef: React.RefObject<HTMLDivElement> }) {

    const { getCountOfTasksInDeliveryNote, tasksToDeliver } = useDeliveryNote()

    const { getCompanyByID } = useContacts()

    const { selectedTasks } = useTasks()

    //Um die Nummer der Tasks im Lieferschein zu bekommen
    const [tasksInDeliveryNote, setTasksInDeliveryNote] = useState(getCountOfTasksInDeliveryNote!(tasks))

    useEffect(() => {
        //* Könnte mit einem State im Context einfacher getriggert werden.
        setTasksInDeliveryNote(getCountOfTasksInDeliveryNote!(tasks))
    }, [tasksToDeliver])

    //Regelt den Kontrast zwischen den einzelnen Task-Elementen
    const backgroundColor = index % 2 === 1 ? "var(--ultra-light)" : "transparent"

    const existingTasksInSelection = selectedTasks.filter((task) => task.customerOrganizationId == tasks[0].customerOrganizationId)

    if (tasks?.length > 0) {
        return (
            <Expandable
                header={(expanded, setExpanded) => (
                    <section className={'flex centered-aligned pointer stretched-justify'.concat(" " + styles.header)} onClick={() => setExpanded((val) => !val)} style={{ backgroundColor: index % 2 === 1 ? "var(--ultra-light)" : "transparent", width: "100%" }}>
                        <span style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: `${cardContentRef.current?.offsetWidth ? cardContentRef.current?.offsetWidth * 0.75 : 250}px`, textOverflow: "ellipsis" }} className='noselect'>{getCompanyByID!(tasks[0].customerOrganizationId!)?.name ?? "Kein Kunde zugeordnet"}</span>
                        <div className='flex centered' style={{ gap: "5px" }}>
                            <span className='bubble'>{tasks.length}</span>
                        </div>
                    </section>
                )}
                body={(
                    <div className={styles.body} style={{ backgroundColor }}>
                        {
                            tasks.map((task, i) => <Task index={i} organizationIndex={index} key={i} mode={mode} task={task} setClickedTask={setClickedTask} setFormMode={setFormMode} openModalOnTaskClick={openModalOnTaskClick} />)
                        }
                    </div>
                )}
                style={{
                    margin: "0 -10px"
                }}
            />
        )
    }
    else {
        return <></>
    }
}

export default TaskExpandable
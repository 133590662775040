import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { card } from '../../Interfaces/Card'

/* Icons */

import { AddRounded } from '@mui/icons-material'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { IProductDTO, useProducts } from '../../Contexts/ProductContext/ProductProvider'
import { productFormModes } from '../../Pages/Verwaltung/Produkte'
import Card from '../Card/Card'
import Filter, { IFilterOption } from '../Filter/Filter'
import ProductRow from './ProductRow'

interface IProductCard extends card {
  setClickedProduct?: Dispatch<SetStateAction<IProductDTO>>
  setFormMode?: Dispatch<SetStateAction<productFormModes>>
}

function ProductCard({ width, height, order, setClickedProduct, setFormMode }: IProductCard) {

  const { products, fetchProducts } = useProducts();
  const productContext = useProducts();

  const [cardProducts, setCardProducts] = useState<IProductDTO[]>([]);

  const originalCardProducts = useMemo(() => products, [products]);

  useEffect(() => {
    if (products) {
      setCardProducts(products);
    }

  }, [products])

  const dialogprovider = useModals();

  useEffect(() => {
    fetchProducts();
  }, [])

  // Zustand für die ausgewählte Tabellenzeile
  const [selectedRow, setSelectedRow] = useState(null);

  // Zustand für die ausgewählte Zelle
  const [selectedCell, setSelectedCell] = useState(null);

  // Funktion, die aufgerufen wird, wenn eine Tabellenzeile ausgewählt wird
  const handleRowClick = (row: any) => {
    setSelectedRow(row);
  };

  const filterOptions: IFilterOption[] = [
    { title: "Titel", valueKey: "title" },
    { title: "Beschreibung", valueKey: "description" }
  ]

  return (
    <Card
      title='Produkte'
      style={{ width, height, order }}
      fullHeightBody={true}
      right={() => <Filter dataToFilter={cardProducts} setDataToFilter={setCardProducts} filterOptions={filterOptions} originalData={originalCardProducts} />}

      cta={true}
      ctaTitle='Produkt erstellen'
      buttonIcon={<AddRounded fontSize='small' />}
      //ToDo: right={() => <TaskCardRight mode={mode} setMode={setMode} showCreateDeliveryNote={tasksToDeliver.length > 0} />}
      //ctaFunction={}
      ctaFunction={() => { dialogprovider.changeVisibility!("editCreateProduct", true, { props: { mode: "create" } }) }}

    >

      {cardProducts && cardProducts.map((product: any) => {

        return (
          <ProductRow product={product} setClickedProduct={setClickedProduct} setFormMode={setFormMode} />
        )
      })}

    </Card>
  )
}

export default ProductCard
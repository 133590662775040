import ProjectSelect from "../../Components/Project/ProjectSelect";
import { Card, CardContent, CardHeader, CardTitle } from "../../Components/UI/CardNew";

export default function ProjectBilling() {
    return (
        <>

            <Card>
                <CardHeader><CardTitle>Projektabrechnung</CardTitle></CardHeader>
                <CardContent>
                    Die Projektabrechnung wird demnächst implementiert!
                    {/* Project Select, anzeigen Projektname + Kunde? */}
                    {/* <ProjectSelect /> */}
                    {/* Rechnungskreis auswaehlen */}
                    {/* Auswahl Leistungszeitraum */}
                    {/* Divider */}
                    {/* Offene Leistungen in Zeitraum */}
                </CardContent>
            </Card>
        </>
    )
}
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useTasks } from '../../../Contexts/TaskContext/TaskContext'
import { ITask, ITaskDTO } from '../../../Interfaces/Task'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateTaskForm from '../../Forms/CreateTaskForm'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import { useMobileView } from '../../../Hooks/useMobileView'
import { IBillDTO, IBillingPosition } from '../../../Interfaces/Bill'
import { useBills } from '../../../Contexts/BillContext/BillProvider'
import dayjs from 'dayjs'


interface props {
  open?: boolean
  onClose?: any
  mode?: string
  billingPositions?: Array<IBillingPosition> | Array<IBillingPosition>
  setBillingPositions?: Dispatch<SetStateAction<Array<IBillingPosition>>>
  bill?: IBillDTO
}

const GenerateTasksDialog = ({ open, onClose, mode, billingPositions, setBillingPositions, bill }: props) => {

  const { defaultTask, addTask, updateTask } = useTasks()
  const { markBillTasksGenerated } = useBills();

  const { changeVisibility } = useModals()

  const [tasksToSubmit, setTasksToSubmit] = useState<Array<ITaskDTO>>([])
  const [taskToSubmit, setTaskToSubmit] = useState<ITaskDTO>({})

  const handleNext = () => {
    if (validateInput(taskToSubmit)) {
      setTasksToSubmit((old) => ([...old, taskToSubmit]));

      if ((billingPositions?.length ?? 0) > (index + 1)) {
        setTaskToSubmit(billingPositionToTask(billingPositions![index + 1], bill!));
        setIndex((old) => (old + 1));
      }
    }
  }

  const handleSubmit = () => {
    if (validateInput(taskToSubmit) && addTask && markBillTasksGenerated) {
      addTask!({ ...taskToSubmit, offerId: bill?.id });
      tasksToSubmit.forEach((task) => {
        addTask!({ ...task, offerId: bill?.id });
      })

      setTasksToSubmit((old) => ([...old, taskToSubmit]));

      markBillTasksGenerated(bill!);

      onClose();
    }
  }

  const handleBack = () => {
    setTasksToSubmit((old) => (old.map((task, i) => {
      if (index === i) {
        return taskToSubmit;
      }
      return task;
    })))
    if ((index - 1) >= 0) {
      setTaskToSubmit(tasksToSubmit[index - 1]);
      setIndex((old) => (old - 1));
    }
  }

  useEffect(() => {
    console.log(taskToSubmit)
  }, [taskToSubmit])

  const billingPositionToTask = (billingPosition: IBillingPosition, bill: IBillDTO) => {
    let task: ITaskDTO = defaultTask ?? {};

    task.customerOrganizationId = bill.customerId;
    task.title = billingPosition.title;
    task.description = billingPosition.description;
    task.creationDate = dayjs();

    return task;
  }

  useEffect(() => {
    setIndex(0)
    setTaskToSubmit((billingPositions && billingPositions.length > 0 ? billingPositionToTask(billingPositions[0], bill!) : {}))
    setTasksToSubmit([])
  }, [billingPositions])

  //form error handling variables
  const [employeeError, setEmployeeError] = useState(false);
  const [customerError, setCustomerError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const mobile = useMobileView()

  function validateInput(task: ITaskDTO) {
    //check if an employee was asigned to task
    if (task?.userIds?.length === 0) {
      setEmployeeError(true);
    } else {
      setEmployeeError(false);
    }

    //check if task is assigned to a customer
    if (!task.customerOrganizationId) {
      setCustomerError(true);
    } else {
      setCustomerError(false);
    }

    if (task?.title?.length === 0) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }

    if (task?.userIds?.length === 0 || !task.customerOrganizationId || task?.title?.length === 0) {
      return false;
    }
    return true;
  }

  const [index, setIndex] = useState(1);

  return (
    <Modal
      title={'Aufgabe erstellen zu Position ' + (index + 1)}
      open={open ?? false}
      onClose={onClose ? () => { onClose(); setTaskToSubmit(defaultTask!); } : () => changeVisibility!("createTask", false)}
    >
      <CreateTaskForm
        object={taskToSubmit}
        setObject={setTaskToSubmit}
        errorVariables={{ employeeError, customerError, titleError }}
      />
      <div style={{ height: "5px" }}></div>
      <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "1rem" }}>
        <CTAButton
          disabled={index === 0}
          title='Zurück'
          onClickHandler={() => handleBack()}
        />
        {index < ((billingPositions?.length ?? 0) - 1) &&
          <CTAButton
            title='Weiter'
            onClickHandler={() => handleNext()}
          />
        }
        {index === ((billingPositions?.length ?? 0) - 1) &&
          <CTAButton
            title='Aufgaben erstellen'
            onClickHandler={() => handleSubmit()}
          />
        }
        {employeeError || titleError || customerError && 
          <div></div>
        }
      </div>
    </Modal>
  )
}

export default GenerateTasksDialog
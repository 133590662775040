import AccountSettingsCard from '../../Components/Card/AccountSettingsCard/AccountSettingsCard'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import PageTabsWrapper from '../../Components/Page/PageTabs'
import PersonalSettingsCard from '../../Components/PersonalSettingsCard/PersonalSettingsCard'
import ProfilePictureCard from '../../Components/ProfilePictureCard/ProfilePictureCard'

function SettingsPersonal() {

  const routes = [
    { path: "/einstellungen/privat", name: "Persönlich" },
    { path: "/einstellungen/unternehmen", name: "Unternehmen" },
    { path: "/einstellungen/standardtexte", name: "Standardtexte" }
  ]

  return (
    <>
      <PageTabsWrapper routes={routes}>
        <CardColumn
          height='100%'
          width='50%'
        >
          <PersonalSettingsCard
            height='60%' />

          <AccountSettingsCard
            height='40%' />
        </CardColumn>

        <CardColumn
          height='100%'
          width='50%'
        >
          <ProfilePictureCard
            height='100%' />
        </CardColumn>
      </PageTabsWrapper>
    </>
  )
}

export default SettingsPersonal
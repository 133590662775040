import { useEffect, useState } from "react";
import { IProjectDTO, defaultProjectDTO, useTasks } from "../../../Contexts/TaskContext/TaskContext";
import CTAButton from "../../Buttons/CTAButton/CTAButton";
import Modal from "../../Dialog/Dialog";
import CreateEditProjectForm from "../../Forms/Projects/CreateEditProjectForm";
import { dialogWithProps } from "../EditCreateCompanyDialog/EditCreateCompanyDialog";
import { useHandleCreateProject, useHandleEditProject } from "../../../redux/requestHandlers/Project";

const EditCreateProjectModal = ({ open, onClose, mode, object }: dialogWithProps<IProjectDTO>) => {

    const [project, setProject] = useState<IProjectDTO>(object ?? defaultProjectDTO);

    const { handleCreateProject, isLoading: isCreateProjectLoading } = useHandleCreateProject();
    const { handleEditProject, isLoading: isEditProjectLoading } = useHandleEditProject();

    const handleSubmit = () => {
        if (mode === "edit") {
            handleEditProject(project);
            if (onClose) onClose();
        }
        else {
            handleCreateProject(project);
            if (onClose) onClose();
        }
    };

    useEffect(() => {
        if (object) setProject(object);
    }, [object])

    return (
        <Modal
            title={mode === "edit" ? "Projekt bearbeiten" : "Projekt erstellen"}
            open={open}
            onClose={onClose}
        >
            <CreateEditProjectForm object={project} setObject={setProject} />

            <CTAButton
                title='Speichern'
                onClickHandler={() => handleSubmit()}
            />
        </Modal>
    )
}

export default EditCreateProjectModal;
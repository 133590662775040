import { useNavigate } from "react-router-dom"
import { ITextModule } from "../../redux/apiSlices/TextModule"
import MoreOptionsButton from "../Buttons/MoreOptionsButton/MoreOptionsButton"
import { convertTextModuleTypeString } from "./utils"

type TextModuleRowProps = {
    textModule: ITextModule
}

export default function DefaultTextModuleRow({ textModule }: TextModuleRowProps) {

    const navigate = useNavigate()

    const options = [
        {
            text: "Bearbeiten",
            function: () => navigate(`/einstellungen/standardtext/${textModule.id}`)
        }
    ]

    return (
        <div className="flex flex-row w-full py-4 hover:bg-default-contrast items-center">
            <div className="w-[40%]">{textModule.title}</div>
            <div className="w-[40%]">{convertTextModuleTypeString(textModule.type)}</div>
            <div className="w-[20%] flex justify-center items-center">
                <MoreOptionsButton options={options} />
            </div>

        </div>
    )
}
import { useEffect, useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { useMobileView } from '../../Hooks/useMobileView'

import { Download } from '@mui/icons-material'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import CTAButton from '../../Components/Buttons/CTAButton/CTAButton'
import Card from '../../Components/Card/Card'
import FormRow from '../../Components/Forms/FormUtils/FormRow'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import { downloadFile } from '../../Contexts/DocumentContext/DocumentContext'
import { IProductDTO as IProduct } from '../../Contexts/ProductContext/ProductProvider'
import { IBillDTO } from '../../Interfaces/Bill'

function DatenerfassungsprotokollPage() {

    const mobile = useMobileView()

    const { defaultTask } = useTasks();

    const [chosenProducts, setChosenProducts] = useState<Array<IProduct>>([]);

    const [bill, setBill] = useState<IBillDTO>({});

    const addChosenProducts = (clickedProduct: IProduct) => {
        let product = chosenProducts.find((temp: IProduct) => temp.id === clickedProduct.id);

        if (product) {
            setChosenProducts((old) => old.map((temp: IProduct) => {
                if (clickedProduct.id === temp.id) {
                    return { ...temp, count: temp.count! + 1 }
                }
                return temp;
            }))
        } else {
            setChosenProducts((old) => ([...old, { ...clickedProduct, count: 1 }]))
        }
    }

    useEffect(() => { console.log(chosenProducts) }, [chosenProducts])

    const { registrierkasse, registrierkasseZertifikat, registrierkasseDatenerfassungsprotokoll } = useBills();
    const { companyData } = useCompanyData();

    const columns: GridColDef[] = [
        { field: 'belegNr', headerName: 'Beleg-Nr.', width: 90 },
        {
            field: 'belegTyp',
            headerName: 'Beleg-Typ',
            width: 150,
            editable: false,
        },
        {
            field: 'timestamp',
            headerName: 'Datum',
            width: 200,
            editable: false,
        },
        {
            field: 'ustNormal',
            headerName: 'Ust 20%',
            type: 'number',
            width: 80,
            editable: false,
            valueGetter: (params: GridValueGetterParams) =>
                (params.row.ustNormal ?? 0).toFixed(2)
        },
        {
            field: 'ustErmaessigt1',
            headerName: 'Ust 13%',
            type: 'number',
            width: 80,
            editable: false,
            valueGetter: (params: GridValueGetterParams) =>
                (params.row.ustErmaessigt1 ?? 0).toFixed(2)
        },
        {
            field: 'ustErmaessigt2',
            headerName: 'Ust 10%',
            type: 'number',
            width: 80,
            editable: false,
            valueGetter: (params: GridValueGetterParams) =>
                (params.row.ustErmaessigt2 ?? 0).toFixed(2)
        },
        {
            field: 'ustNull',
            headerName: 'Ust 0%',
            type: 'number',
            width: 80,
            editable: false,
            valueGetter: (params: GridValueGetterParams) =>
                (params.row.ustNull ?? 0).toFixed(2)
        },
        {
            field: 'umsatzzaehlerstand',
            headerName: 'Umsatzzaehlerstand',
            type: 'number',
            width: 150,
            editable: false,
            valueGetter: (params: GridValueGetterParams) =>
                (params.row.umsatzzaehlerstand ?? 0).toFixed(0)
        },
        {
            field: 'kassenId',
            headerName: 'Kassen-Id',
            width: 160,
            editable: false,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
    ];

    const [downloading, setDownloading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    // Example usage
    const handleDownload = () => {
        setDownloading(true);
        downloadFile({ title: "" }, `/registrierkasse/datenerfassungsexport`, (event: any) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            setProgress(progress);
        }).then(() => {
            setDownloading(false);
        });
    }

    return (
        <>

            <CardColumn
                height='100%'
                width='75%'
            >
                <Card
                    title="Erfasste Belege"
                    fullHeightBody
                >
                    <div style={{ height: "90%" }}>
                        <DataGrid
                            rows={registrierkasseDatenerfassungsprotokoll ?? []}
                            columns={columns}
                            autoPageSize
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </div>
                </Card>
            </CardColumn>
            <CardColumn
                height='100%'
                width='25%'
            >
                <Card
                    title='Registrierkasse'
                    fullHeightBody
                    disableScroll
                >
                    <div
                        style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "0.5em" }}
                    >
                        <FormRow
                            label='KassenID'
                            onChange={() => { }}
                            type='text'
                            readonly
                            value={registrierkasse?.kassenId}
                        />
                        <FormRow
                            label='Zertifikatsseriennummer Hexadezimal'
                            onChange={() => { }}
                            type='text'
                            readonly
                            value={registrierkasseZertifikat?.seriennummerHex}
                        />
                        <FormRow
                            label='Zertifikatsseriennummer Dezimal'
                            onChange={() => { }}
                            type='text'
                            readonly
                            value={registrierkasseZertifikat?.seriennummerDec}
                        />
                        <FormRow
                            label='AES-Key (Verschlüsselung)'
                            onChange={() => { }}
                            type='text'
                            readonly
                            value={registrierkasse?.key}
                        />
                        <CTAButton
                            style={{ marginTop: "1.5em" }}
                            onClickHandler={() => {
                                handleDownload();
                            }}
                            title={<span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><Download fontSize='inherit' style={{ marginRight: "0.5em" }} /> Export Datenerfassungsprotokoll</span>}
                        />
                    </div>
                </Card>
            </CardColumn>
        </>


    )
}

export default DatenerfassungsprotokollPage
import { useEffect, useState } from "react";
import { IProductDTO } from "../../Contexts/ProductContext/ProductProvider";
import { IDeliveryNoteDTO, IDeliveryNotePosition } from "../../Pages/Services/LieferscheinErstellen";
import { useTasks } from "../../Contexts/TaskContext/TaskContext";

export const useScannedProductsForDeliveryNote = (deliveryNoteDTO: IDeliveryNoteDTO, setDeliveryNoteDTO: React.Dispatch<React.SetStateAction<IDeliveryNoteDTO>>, generatePdf: (deliveryNote: IDeliveryNoteDTO) => void) => {
    const [scannedProducts, setScannedProducts] = useState<IProductDTO[]>([]);
    const { addDeliveryNotePosition, updateDeliveryNotePosition } = useTasks();

    useEffect(() => {
        console.log("scannedProducts changed", scannedProducts);
        console.log("deliveryNoteDTO", deliveryNoteDTO);

        let deliveryNotePositionsToUpdate = deliveryNoteDTO.positions ? [...deliveryNoteDTO.positions] : [];

        scannedProducts.forEach(async scannedProduct => {
            let deliveryNotePosition = deliveryNotePositionsToUpdate.find(position => position.productId === scannedProduct.id);

            if (deliveryNotePosition) { // if it is already included as a position, update quantity
                deliveryNotePosition.quantity = scannedProduct.count;
                if (updateDeliveryNotePosition) updateDeliveryNotePosition(deliveryNotePosition);
            } else { // Add product as a new position
                let newDeliveryNotePosition: IDeliveryNotePosition = {
                    deliveryNoteId: deliveryNoteDTO.id,
                    productId: scannedProduct.id,
                    quantity: scannedProduct.count,
                    title: scannedProduct.title,
                    unit: 0
                }
                if (addDeliveryNotePosition) newDeliveryNotePosition = await addDeliveryNotePosition(newDeliveryNotePosition);
                deliveryNotePositionsToUpdate.push(newDeliveryNotePosition);
            }
        });

        updateDeliveryNotePositions(deliveryNotePositionsToUpdate);
        generatePdf({ ...deliveryNoteDTO, positions: deliveryNotePositionsToUpdate });
        console.log("updated delivery note positions", deliveryNotePositionsToUpdate);
    }, [scannedProducts]);

    return {
        scannedProducts,
        setScannedProducts,
    }

    function updateDeliveryNotePositions(updatedDeliveryNotePositions: IDeliveryNotePosition[]) {
        setDeliveryNoteDTO((deliveryNoteDTO: IDeliveryNoteDTO) => ({
            ...deliveryNoteDTO,
            positions: updatedDeliveryNotePositions
        }))
    }
}

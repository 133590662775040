import { useEffect } from 'react'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useEmployees } from '../../Contexts/EmployeeContext/EmployeeProvider'
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { IService, IServiceDTO } from '../../Interfaces/Service'
import { serviceFormModes } from '../../Pages/Services/Services'
import { useGetAllProjectsQuery } from '../../redux/apiSlices/Project'
import ResetFormButton from '../Buttons/ResetFormButton/ResetFormButton'
import Android12Switch from '../Buttons/Switch/Android12Switch'
import { InputFieldErrorMessages } from '../InputField/InputFieldRefactored'
import AddProductFormRow from '../Product/AddProductFormRow'
import { form } from './CreateTaskForm'
import FormRow from './FormUtils/FormRow'

const CreateServiceForm = ({ object, setObject, setFormMode, errorVariables }: form<IServiceDTO | IService, serviceFormModes>) => {
    const { companies } = useContacts()
    const { user } = useUser()

    const { employees } = useEmployees()

    const { defaultService, taskServiceCategories } = useServices();
    const { data: projects } = useGetAllProjectsQuery();

    const handleReset = () => {
        setObject(defaultService!);
        setFormMode!("createService");
    }

    useEffect(() => {
        console.log("create service form", object);
    }, [object])

    return (
        <form className="form">
            <FormRow
                value={object?.employeeDatas?.length > 0 ? object?.employeeDatas[0].userId : user?.userId!}
                type="select"
                onChange={(val) => setObject((old) => ({ ...old, employeeDatas: [{ ...(old?.employeeDatas[0] ?? {}), userId: val }] }))}
                label="Mitarbeiter*in"
                placeholder='Mitarbeiter*in auswählen'
                selectOptions={{
                    options: employees,
                    titleKey: "username",
                    valueKey: "id",
                }}
                error={errorVariables?.employeeError}
                errorMessage={InputFieldErrorMessages.EmployeeRequired}
            />
            <FormRow
                value={object.clientOrganizationId}
                onChange={(val) => setObject((old) => ({ ...old, clientOrganizationId: val }))}
                label="Kunde"
                type='select'
                placeholder='Kunde auswählen'
                selectOptions={{
                    options: companies,
                    titleKey: "name",
                    valueKey: "id"
                }}
                error={errorVariables?.customerError}
                errorMessage={InputFieldErrorMessages.CustomerRequired}
            />
            <FormRow
                value={object.projectId}
                type="select"
                placeholder='Projekt'
                onChange={(val) => setObject((old) => ({ ...old, projectId: val }))}
                label="Projekt zuordnen (optional)"
                selectOptions={{
                    options: projects ?? [],
                    titleKey: "title",
                    valueKey: "id"
                }}
            />
            <FormRow
                type='text'
                value={object.title}
                onChange={(title) => setObject({ ...object, title })}
                label="Bezeichnung"
                placeholder="Titel"
                error={errorVariables?.titleError}
                errorMessage={InputFieldErrorMessages.TitleError}
            />
            <FormRow
                type='multiline'
                label="Beschreibung"
                value={object.description}
                onChange={(val) => setObject((old) => ({ ...old, description: val }))}
                placeholder='Beschreibung (max. 1500 Zeichen)'
                maxLength={1500}
            />
            <FormRow
                type='select'
                label="Kategorie (optional)"
                value={object.categoryId}
                onChange={(val) => setObject((old) => ({ ...old, categoryId: val }))}
                selectOptions={{
                    options: taskServiceCategories,
                    titleKey: "name",
                    valueKey: "id"
                }}
            />
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <FormRow
                    value={object.employeeDatas?.length > 0 ? object.employeeDatas[0]?.from : undefined}
                    /* @ts-ignore */
                    onChange={(val) => setObject((old) => ({ ...old, employeeDatas: [{ ...old.employeeDatas[0], from: val ? val : null }] }))}
                    label="Start"
                    type="date"
                    dateOptions={{
                        type: "DateTime"
                    }}
                    error={errorVariables?.dateStartError}
                    errorMessage={InputFieldErrorMessages.StartDateError}
                />
                <FormRow
                    value={object.employeeDatas?.length > 0 ? object.employeeDatas[0]?.to : null}
                    /* @ts-ignore */
                    onChange={(val) => setObject((old) => ({ ...old, employeeDatas: [{ ...old.employeeDatas[0], to: val ? val : null }] }))}
                    label="Ende"
                    type="date"
                />
            </div>
            <FormRow
                value={object.employeeDatas?.length > 0 ? object.employeeDatas[0]?.hourlyRate : 80}
                onChange={(val) => setObject((old) => ({ ...old, employeeDatas: [{ ...old.employeeDatas[0], hourlyRate: Number.isNaN(parseInt(val?.toString())) ? 0 : parseInt(val?.toString()) }] }))}
                type="number"
                label="Stundensatz"
                placeholder='Stundensatz'
            />
            <AddProductFormRow object={object} setObject={setObject}/>
            <div className='form-row noselect' onClick={() => setObject((oldVal) => ({ ...oldVal, billable: !object.billable }))}>
                <span>Verrechenbar</span>
                <Android12Switch color="secondary" checked={object.billable} onChange={(e, val) => setObject((oldVal) => ({ ...oldVal, billable: val }))} />
            </div>
            <div className='form-row noselect' onClick={() => setObject((oldVal) => ({ ...oldVal, billed: !object.billed }))}>
                <span>Verrechnet</span>
                <Android12Switch color="secondary" checked={object.billed} onChange={(e, val) => setObject((oldVal) => ({ ...oldVal, billed: val }))} />
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <ResetFormButton
                    style={{ width: "33%" }}
                    title="Felder zurücksetzen"
                    secondary={false}
                    onClickHandler={() => handleReset()}
                />
            </div>
        </form>
    )
}

export default CreateServiceForm